
const _baseUrl = process.env.REACT_APP_BASE_URL;
if (_baseUrl === undefined)
    throw new Error("REACT_APP_BASE_URL is undefined!")
const baseUrl = _baseUrl.endsWith("/") ? _baseUrl : _baseUrl + "/";

let lastKnownVersion = -1;
function setLastKnownVersion(version: number) {
    lastKnownVersion = version;
}

function getLastKnownVersion() {
    return lastKnownVersion;
}

function url(base: string): string;
function url(base: string, path: string): string;
/**
 * expectations:
 *  baseUrl ends with /
 *  base does not have a leading /
 *  base can have a trailing /
 *  path can omit leading /
 */
function url(base: string, path: string = ""): string {
    if (path !== "") {
        if ( ! path.startsWith("/")) {
            path = "/" + path
        }
        if ( base.endsWith("/")) {
            base = base.substring(0, base.length - 1);
        }
    }
    return baseUrl + base + path;
}

/*
    Type that lists all keys in an object
 */
export type ApiConfigInput<Type> = {
    [Property in keyof Type]: string
}

/*
    Map all keys from InputType to a url builder function
 */
export type ApiConfig<Type> = {
    urls: { [Property in keyof ApiConfigInput<Type>]: (path?: string) => string }
    getLastKnownVersion: () => number,
    setLastKnownVersion: (version: number) => void,
}

/*
    Takes in an object { key1: endpointBase1, key2: endpointBase2... } and
    returns an object { getLastKnownVersion: () => number, setLastKnownVersion(number) => void, urls: {URL_BUILDERS} }
    with URL_BUILDERS = { key1: (path?: string) => string, key2: (path?: string) => string... }. These functions
    delegate to url(endpointBaseX, path)
 */
export function apiConfig<T>(bases: ApiConfigInput<T>): ApiConfig<T>  {
    // @ts-ignore suppress, keys are missing, loop will add them
    const result: ApiConfig<ApiConfigInput<T>> = { urls: {}, getLastKnownVersion, setLastKnownVersion }
    for (let basesKey in bases) {
        const basePath = bases[basesKey].startsWith("/") // remove leading / and decouple from input object
            ? bases[basesKey].substring(1)
            : bases[basesKey];
        result.urls[basesKey] = (path?: string) => url( basePath, path??"" )
    }
    return result
}
import React, {useContext, useState} from "react";
import {Auth} from "aws-amplify";
import UserLoginComponent from "../components/Login/UserLoginComponent";
import GuestLoginComponent from "../components/Login/GuestLoginComponent";
import {getCurrentUser} from "@2gether/frontend-library";
import { Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {BoardClient} from "../api/BoardClient";
import {Board} from "../model/Board";
import {UserContext} from "../contexts/UserContext";

interface Props {
    setWithToken: (token: string) => void,
    board: Board
    guestAccess: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        parentDiv: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        retroStartDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
        },
        contentDiv: {
            width: "400px",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        textField: {
            margin: "5px"
        },
        button: {
            marginTop: "3px"
        }
    }))

const LoginPage: React.FC<Props> = (props: Props) => {
    const {setWithToken, board, guestAccess} = props
    const [error, setError] = useState<boolean>(false)
    const {updateUser} = useContext(UserContext)
    const styles = useStyles()
    const signIn = (email: string, password: string) => {
        Auth.signIn(email.toLowerCase(), password)
            .then(async (user) => {
                await getCurrentUser()
                    .then(() => {
                        checkContact(user)
                    })
                updateUser()
            })
            .catch(() => {
                setError(true)
            })
    }

    const checkContact = (user: any) => {
        Auth.verifiedContact(user).then((data: any) => {
            if (Object.keys(data.verified).length !== 0) {
                BoardClient.getSingleton()
                    .createUserToken({name: user.attributes.given_name.toString() + " " + user.attributes.family_name.toString(),
                        id: user.username,
                        boardId: board.id})
                    .then(token => setWithToken(token))
                    .catch(() => setError(true))
            } else {
                setError(true)
            }
        })
    }

    const signInAsGuest = (name: string) => {

        BoardClient.getSingleton()
            .createUserToken({name, id:null, boardId: board.id})
            .then(token => setWithToken(token))
    }

    return (
        <Grid container alignItems="center" direction="column">
            <Grid item sm>
                {guestAccess ||
                <UserLoginComponent styles={styles} signIn={signIn} error={error} setError={setError}/>}
            </Grid>
            <Grid item sm>
                <GuestLoginComponent styles={styles} signIn={signInAsGuest}/>
            </Grid>
        </Grid>
    )
}

export default LoginPage
import React from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {FormattedMessage} from "react-intl";

export interface ConfirmationDialogProps {
    open: boolean
    message: string
    onAccept: () => void
    onCancel: () => void
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const {open, message, onAccept, onCancel} = props

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth={"xs"}>
            <DialogTitle>{message}</DialogTitle>
            <DialogActions>
                <Button onClick={onAccept}>
                    <FormattedMessage id={"confirmation-dialog-confirm"}/>
                </Button>
                <Button onClick={onCancel} style={{color: "rgba(0, 0, 0, 0.87)"}}>
                    <FormattedMessage id={"confirmation-dialog-cancel"}/>
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default ConfirmationDialog
/**
 * Limits the number of times a function can fire.
 * @param func The function to execute.
 * @param wait The time in ms between executions (default: 250).
 * @param immediate Execute function before timeout (default: true)?
 */
export function debounce<T extends Array<any>>(func: (...args: T) => void, wait: number = 250, immediate: boolean = true): (...args: T) => void {
    let timeout: NodeJS.Timeout | null;

    return (...args: T) => {
        const later = function () {
            timeout = null;
            if (!immediate)
                func(...args);
        };
        const callNow = immediate && !timeout;

        if (timeout !== null && timeout !== undefined)
            clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow)
            func(...args)
    }
}

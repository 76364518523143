import {Button, Grid} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DragStart,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import ColumnComponent from "../Columns/ColumnComponent";
import {Board} from "../../model/Board";
import {
    handleMerge,
    handleMoveToCard,
    handleMoveToColumn,
    handleSortedMerge,
    handleSplitToCard,
    handleSplitToColumn,
    mergeOptimistically,
    moveToCardOptimistically,
    moveToColumnOptimistically,
    sortedMergeOptimistically,
    splitToCardOptimistically,
    splitToColumnOptimistically
} from "../../services/CardMoveServices";
import {CookieManager} from "../../controller/CookieManager";
import {usePrevious} from "react-use";
import {handleColumnMove, moveColumnOptimistically} from "../../services/ColumnMoveServices";
import AddIcon from "@mui/icons-material/Add";
import CreateColumnOnBoardPageComponent from "../Columns/CreateColumnOnBoardPageComponent";
import {isVerifiedAdmin} from "../../controller/AdminVerificator";
import {BoardClient} from "../../api/BoardClient";
import {setErrorMessageContext, userTokenContext} from "../../pages/BoardPage";
import {Column} from "../../model/Column";
import {colorOptions} from "../Columns/CreateColumnComponent";


interface Props {
    board: Board,
    columns: Column[],
    name: string,
    votesVisible: boolean,
    votingVisible: boolean,
    downVotingVisible: boolean,
    sorted: boolean,
    editActive: boolean,

    setEditActive(newState: boolean): void,

    votesUnlimited: boolean,
    value: boolean

    isCardEditable: number
    setIsCardEditable: (value: number) => void
}


export enum DragStates {
    "none",
    "simpleCard",
    "mergedCard",
    "column"
}

export const COLUMN_MAX_WIDTH = 600
export const COLUMN_MIN_WIDTH = 250
export const COLUMN_MARGIN = 8
export const ADD_COLUMN_BUTTON_WIDTH = 64

const useStyles = makeStyles((theme) =>

    createStyles({
        columnLayoutGridItem: {
            width: "100%",
            maxWidth: COLUMN_MAX_WIDTH + "px",
            minWidth: COLUMN_MIN_WIDTH + "px",
            margin: COLUMN_MARGIN + "px",
        },
        addColumnButton: {
            margin: "24px 24px",
            width: ADD_COLUMN_BUTTON_WIDTH + "px"
        }
    })
);

const ColumnLayoutComponent: React.FC<Props> = (props) => {
    const styles = useStyles();
    const {
        board: propBoard,
        columns: propColumns,
        name,
        votesVisible,
        votingVisible,
        downVotingVisible,
        sorted,
        editActive,
        setEditActive,
        votesUnlimited,
        isCardEditable,
        setIsCardEditable
    } = props;
    const prevPropBoard = usePrevious(propBoard);
    const [dragActive, setDragActive] = useState(DragStates.none);
    const [board, setBoard] = useState(propBoard);
    const [currentDragSourceColumn, setCurrentDragSourceColumn] = useState<number | null>(null);
    const [currentDraggableId, setCurrentDraggableId] = useState<string | null>(null);
    const [showCreateColumnComponent, setShowCreateColumnComponent] = useState<boolean>(false)
    const setErrorMessage = useContext(setErrorMessageContext)
    const {value} = props;
    const token = useContext(userTokenContext) ?? ""

    const isAdmin = useMemo(() => {
        return isVerifiedAdmin(token)
    }, [token])

    useEffect(() => {
        if (propBoard !== prevPropBoard) {
            if (dragActive === DragStates.none && !editActive) {
                setBoard(propBoard);
            }
        }
    }, [propBoard, setBoard, dragActive, editActive, prevPropBoard]);

    const setEditState = useCallback((newState: boolean) => {
        setEditActive(newState)
        if (!newState && propBoard !== prevPropBoard) {
            setBoard(propBoard)
        }
    }, [prevPropBoard, propBoard, setEditActive])

    const isDeletable = board.columns.length > 1

    const onDragEnd = useCallback((result: DropResult) => {
        setDragActive(DragStates.none);
        setCurrentDragSourceColumn(null);
        setCurrentDraggableId(null);

        const {combine, destination, source} = result;
        if (combine !== null && combine !== undefined) {
            handleMerge(board, result, token).then().catch(setErrorMessage);
            mergeOptimistically(board, result, setBoard);
        } else if (destination !== null && destination !== undefined && source !== null && source !== undefined) {
            if (result.destination!!.droppableId.startsWith("merge")) {
                handleSortedMerge(board, result, token).then().catch(setErrorMessage);
                sortedMergeOptimistically(board, result, setBoard);
            } else if (result.draggableId.startsWith("subcard")) {
                if (destination.droppableId.startsWith("header")) {
                    handleSplitToColumn(board, result, token).then().catch(setErrorMessage);
                    splitToColumnOptimistically(board, result, setBoard);
                } else if (!sorted && destination.droppableId.startsWith("col")) {
                    handleSplitToCard(board, result, token).then().catch(setErrorMessage);
                    splitToCardOptimistically(board, result, setBoard);
                }
            } else if (result.draggableId.startsWith("card")) {
                if (destination.droppableId.startsWith("header")) {
                    handleMoveToColumn(board, result, token).then().catch(setErrorMessage);
                    moveToColumnOptimistically(board, result, setBoard);
                } else if (!sorted && destination.droppableId.startsWith("col")) {
                    handleMoveToCard(board, result, token).then().catch(setErrorMessage);
                    moveToCardOptimistically(board, result, setBoard);
                }
            } else if (result.draggableId.startsWith("col")) {
                if (destination.droppableId.startsWith("board")) {
                    handleColumnMove(board, result, token).then().catch(setErrorMessage);
                    moveColumnOptimistically(board, result, setBoard);
                }
            }
        }

    }, [board, setDragActive, setCurrentDragSourceColumn, sorted, setCurrentDraggableId, token, setBoard, setErrorMessage]);

    const onDragStart = useCallback((initial: DragStart) => {
        setCurrentDraggableId(initial.draggableId);
        if (initial.draggableId.startsWith("card")) {
            setDragActive(DragStates.simpleCard);
            if (initial.source !== null && initial.source !== undefined) {
                setCurrentDragSourceColumn(Number(initial.source.droppableId.split("-")[1]))
            }
        }
        if (initial.draggableId.startsWith("subcard")) {
            setDragActive(DragStates.mergedCard);
            setCurrentDragSourceColumn(Number(initial.draggableId.split("-")[1]))
        }
        if (initial.draggableId.startsWith("col")) {
            setDragActive(DragStates.column);
        }
    }, [setDragActive, setCurrentDraggableId]);


    const addColumnButton = useMemo(() => {
        if (isVerifiedAdmin(CookieManager.getJWSToken(board.id, name)) && board.columns.length < 10 && !showCreateColumnComponent) {
            return (
                <Grid item className={styles.addColumnButton}>
                    <Button
                        id={"button-add-column"}
                        onClick={() => {
                            setShowCreateColumnComponent(true)
                            setEditActive(true)
                        }}
                        disabled={showCreateColumnComponent || editActive}
                        fullWidth
                        style={{color: "rgba(0, 0, 0, 0.87)"}}
                    >
                        <AddIcon/>
                    </Button>
                </Grid>
            )
        } else {
            return null
        }
    }, [board.columns.length, board.id, name, showCreateColumnComponent, editActive, styles.addColumnButton, setEditActive])

    const unusedColors = useMemo(() => {
        const usedColors = propColumns.map(column => column.color)
        return colorOptions.filter(color => !usedColors.includes(color))
    }, [propColumns])

    const calculateUserVotes = (columns: Column[]): number => {
        let votesSum = 0;
        columns.map(column => column.cards.map(card => votesSum += card.votesForCardFromUser))
        return votesSum
    }

    const calculateUserDownVotes = (columns: Column[]): number => {
        let downVotesSum = 0;
        columns.map(column => column.cards.map(card => downVotesSum += card.downVotesForCardFromUser))
        return downVotesSum
    }

    const ColumnCreationComponent = () => {
        if (showCreateColumnComponent) {
            return (
                <Grid item className={styles.columnLayoutGridItem}>
                    <CreateColumnOnBoardPageComponent
                        unusedColors={unusedColors}
                        acceptCallback={(cardText: string, anonymous: boolean, color: string) => {
                            setShowCreateColumnComponent(false)
                            setEditActive(false)
                            BoardClient.getSingleton()
                                .createColumn(board, {
                                    sourceBoardVersionId: board.boardVersion,
                                    text: cardText,
                                    anonymous,
                                    color
                                }, token)
                                .catch(setErrorMessage)
                        }}
                        cancelCallback={() => {
                            setShowCreateColumnComponent(false)
                            setEditActive(false)
                        }}
                    />
                </Grid>
            )
        } else return null
    }

    const userVotesSum = calculateUserVotes(propColumns)

    const userDownVotesSum = calculateUserDownVotes(propColumns)

    const calcMinWidth = () => {
        // +1 for the CreateColumnComponent, 10 due to the current column limit, 48 due to 2*24 padding
        return Math.min(propColumns.length + 1, 10) * (COLUMN_MIN_WIDTH + 2 * COLUMN_MARGIN) + 48;
    };

    const calcMaxWidth = () => {
        return propColumns.length * (COLUMN_MAX_WIDTH + 2 * COLUMN_MARGIN)
    }
    return (
        <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
        >
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                wrap="nowrap"
                style={{
                    minWidth: calcMinWidth() + "px"
                }}
            >
                <Droppable droppableId={"board"} type={"COLUMNS"} direction={"horizontal"}>
                    {((provided: DroppableProvided) => {
                        let border = undefined
                        if (dragActive === DragStates.column)
                            border = "dashed grey";
                        return (
                            <Grid
                                item
                                container
                                spacing={0}
                                direction="row"
                                justifyContent="center"
                                alignItems="baseline"
                                wrap="nowrap"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    maxWidth: calcMaxWidth() + "px",
                                    border: border,
                                    boxSizing: "border-box"
                                }}>
                                {propColumns.sort((c1, c2) => {
                                    return c1.position - c2.position
                                })
                                    .map((column) => {
                                        return (
                                            <Draggable draggableId={`col-${column.position}`}
                                                       index={column.position}
                                                       isDragDisabled={!isAdmin || editActive}>
                                                {(draggableProvided: DraggableProvided) => (
                                                    <Grid key={column.position} item
                                                          className={styles.columnLayoutGridItem} {...draggableProvided.draggableProps}
                                                          ref={draggableProvided.innerRef} xs>
                                                        <ColumnComponent
                                                            value={value}
                                                            column={column}
                                                            board={board}
                                                            name={name}
                                                            dragActive={dragActive}
                                                            editActive={editActive}
                                                            setEditActive={setEditState}
                                                            votesVisible={votesVisible}
                                                            votingVisible={votingVisible}
                                                            downVotingVisible={downVotingVisible}
                                                            sorted={sorted}
                                                            currentDragSourceColumn={currentDragSourceColumn}
                                                            currentDraggableId={currentDraggableId}
                                                            columnDragHandle={draggableProvided.dragHandleProps}
                                                            isDeletable={isDeletable}
                                                            votesUnlimited={votesUnlimited}
                                                            userVotesSum={userVotesSum}
                                                            userDownVotesSum={userDownVotesSum}
                                                            isCardEditable={isCardEditable}
                                                            setIsCardEditable={setIsCardEditable}
                                                        />
                                                    </Grid>)}
                                            </Draggable>
                                        )
                                    })}
                                <Grid item style={{padding: "0px"}}>
                                    {provided.placeholder}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Droppable>
                <Grid item style={{paddingLeft: "24px"}}>
                    {addColumnButton}
                    <ColumnCreationComponent/>
                </Grid>
            </Grid>
        </DragDropContext>
    );
};

export default React.memo(ColumnLayoutComponent)

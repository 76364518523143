import React from 'react'
import {Grid} from '@mui/material'
import {UserIsReady} from "../../model/UserIsReady";
import {ReadinessToggleComponent} from "./ReadinessToggleComponent";
import {useIntl} from "react-intl";

export interface IReadinessComponent {
    done: boolean
    userToDone: Map<string, boolean>
    onChange: (state: boolean) => any,
    label: string,
    id: string
}

export const ReadinessComponent: React.FC<{
    settings: {
        readinessRequested: boolean,
        voting: boolean,
        userStates: UserIsReady[],
        userIsDoneWithVotes: boolean,
        userIsDoneWithCards: boolean
    },
    onChangeVote: (state: boolean) => any,
    onChangeCards: (state: boolean) => any,
}> = ({
          settings,
          onChangeVote,
          onChangeCards,
      }) => {

    const intl = useIntl();

    const getReadyFlagsCards = () => {
        let readCards = new Map<string, boolean>()
        settings.userStates.forEach(it => readCards.set(it.userName, it.cardsReady))
        return readCards
    }

    const getReadyFlagsVoting = () => {
        let voteCards = new Map<string, boolean>()
        settings.userStates.forEach(it => voteCards.set(it.userName, it.votingReady))
        return voteCards
    }

    return (
        <Grid container
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              spacing={2}>
            {settings.readinessRequested && <Grid item>
                <ReadinessToggleComponent
                    done={settings.userIsDoneWithCards}
                    userToDone={getReadyFlagsCards()}
                    onChange={onChangeCards}
                    label={intl.formatMessage({id: 'ready-component-card-finish'})}
                    id={'readiness_component-switch-cards-ready'}
                />
            </Grid>}
            {settings.readinessRequested && settings.voting && <Grid item>
                <ReadinessToggleComponent
                    done={settings.userIsDoneWithVotes}
                    userToDone={getReadyFlagsVoting()}
                    onChange={onChangeVote}
                    label={intl.formatMessage({id: 'ready-component-vote-finish'})}
                    id={'readiness_component-switch-voting_ready'}
                />
            </Grid>}
        </Grid>
    );
}



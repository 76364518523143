import {Grid} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import React, {useContext} from "react";
import {FormattedMessage, IntlProvider} from "react-intl";
import {LocaleContext} from "../contexts/LocaleContext";
import enLocale from "../locales/en.json";
import deLocale from "../locales/de.json";

export const NotAuthorizedPage = () => {
    const locale = useContext(LocaleContext).locale === 'en' ? 'en' : 'de';
    const messages = {
        en: enLocale,
        de: deLocale
    }
    return (
        <IntlProvider locale={locale} messages={messages[locale]} key={'notauth'}>
            <Grid id={"message_on_page_not-authorized"}
                  container
                  direction={"column"}
                  spacing={2}>
                <Grid item>
                    <h2><FormattedMessage id={'not-auth-page'}/></h2>
                </Grid>
                <Grid item>
                    <SentimentVeryDissatisfiedIcon/>
                </Grid>
            </Grid>
        </IntlProvider>
    )
}
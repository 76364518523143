import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, {useState} from 'react'
import ColumnEditorComponent from "./ColumnEditorComponent";

interface Props {
    unusedColors: string[],
    acceptCallback(cardText: string, anonymous: boolean, color: string): void,
    cancelCallback(): void
}
interface PropsWithColor extends Props{
    color: string
}

const useStyles = makeStyles((theme) =>

    createStyles({
        createColumnContainer: (props: PropsWithColor) => ({
            width: "100%",
            color: theme.palette.text.secondary
        }),
        createColumnDiv: {
            borderBottom: "solid 1px gray"
        }
    })
);



const CreateColumnOnBoardPageComponent: React.FC<Props> = (props) => {
    const { unusedColors, acceptCallback, cancelCallback } = props;

    const color = unusedColors.length > 0 ? unusedColors[Math.floor(Math.random() * unusedColors.length)] : 
    `rgba(${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, 0.25)`
    const styles = useStyles({...props, color})
    const [columnColor,setColumnColor]=useState(color);
    const handleColumnColor=(color:string)=>{
        setColumnColor(color);
    }
    return (
        <div className={styles.createColumnContainer} >
            <div className={styles.createColumnDiv}>
                <ColumnEditorComponent    changeColumnColor={handleColumnColor} initialAnonymous={false}  onAccept={acceptCallback} onCancel={cancelCallback} color={columnColor}/>
            </div>
        </div>
    )
}

export default CreateColumnOnBoardPageComponent



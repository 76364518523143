import {Auth} from "aws-amplify";

export type Headers = {[key: string]: string}

export async function withAuthenticationHeader(headers?: Headers): Promise<Headers> {
    return Auth.currentSession()
        .then(session => session.getIdToken().getJwtToken())
        .then(token => Object.assign({}, headers??{}, {"Authorization": `Bearer ${token}`}))
}

export function withJsonContentHeader(headers?: Headers): Headers {
    return Object.assign({}, headers??{}, {'Content-Type': 'application/json'})
}

export function withUserTokenHeader(token: string, headers?: Headers): Headers {
    return Object.assign({}, headers??{}, {'X-Retro-JWS-token': token})
}
import { Button } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import materna_theme from "../styles/materna_theme";
import React from 'react'
import {debounce} from "../utilities";


interface Props {
    id: string,
    icon: JSX.Element,
    onClick(): void,
    backgroundColor?: string,
    disabled: boolean,
    hoverColor?: string,
    hoverBackgroundColor?: string,
    size?: string,
    margin?: string
    variant?: "text" | "outlined" | "contained" | undefined
}

const useStyles = makeStyles(() =>

    createStyles({
        smallButton: (props: Props) => ({
            maxHeight: props.size ?? "25px",
            maxWidth: props.size ?? "25px",
            minHeight: props.size ?? "25px",
            minWidth: props.size ?? "25px",
            padding: "0px",
            color: materna_theme.palette.secondary.main,
            margin: props.margin ?? "0px",
            backgroundColor: props.backgroundColor ?? undefined,
            '&:hover': {
                color: props.hoverColor ?? undefined,
                background: props.hoverBackgroundColor ?? undefined,
            }
        })
    })
);

const SmallButtonComponent: React.FC<Props> = (props) => {
    const styles = useStyles(props)

    const {id, icon, onClick, disabled, variant} = props

    const debouncedClick = debounce(onClick)

    return (
        <Button
            id={id}
            className = {styles.smallButton}
            variant={variant ?? "contained"}
            disabled={disabled}
            onClick={event => {event.stopPropagation(); debouncedClick()}}>
            {icon}
        </Button>
    )
}

export default SmallButtonComponent



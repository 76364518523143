import {Column, ColumnDto} from './Column';
import {BoardTimer, BoardTimerDto} from './BoardTimer';
import {UserIsReady, UserIsReadyDto} from "./UserIsReady";

export interface Board {
    showVotes: boolean;
    voting: boolean;
    downVoting: boolean;
    id: string
    name: string
    showAllCards: boolean
    multiVoteAllowed: boolean
    columns: Column[]
    boardVersion: number
    votesLimited: boolean
    // <= 0 is considered infinite
    maxVotes: number
    playMusic: boolean
    highlightedCardId: number
    readinessRequested: boolean
    readyFlags: UserIsReady[]
    userIsDoneWithVotes: boolean
    userIsDoneWithCards: boolean
    highlightMode: HighlightModes
    timer: BoardTimer
    showCardAuthors: boolean
}

export interface BoardCreationDto {
    board: BoardDto;
    adminUserToken: string;
}

export enum HighlightModes {
    Red = "Red",
    CardColor = "CardColor",
    FadeOut = "FadeOut"
}

export interface BoardDto {
    showVotes: boolean;
    voting: boolean;
    downVoteAllowed: boolean;
    id: string
    showAllCards: boolean
    multiVoteAllowed: boolean
    name: string
    columns: ColumnDto[]
    boardVersion: number
    votesLimited: boolean
    maxVotes: number
    playMusic: boolean
    highlightedCardId: number
    readinessRequested: boolean
    readyFlags: UserIsReadyDto[]
    userIsDoneWithVotes: boolean
    userIsDoneWithCards: boolean
    highlightMode: string
    timer: BoardTimerDto
    showingAuthorNames: boolean
}

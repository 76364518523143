import { Divider, Paper, Typography } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, {useMemo} from 'react'
import CardEditorComponent from "./CardEditorComponent";

interface Props {
    columnId: number,
    authorName: string,
    acceptCallback(cardText: string): void,
    cancelCallback(): void,
    isCardAnonymous: boolean
    colour: string
}

const useStyles = makeStyles((theme) =>

    createStyles({
        createCardContainer: {
            width: "100%",
            color: theme.palette.text.secondary,
        },
        createCardDiv: (props: Props) => ({
            padding: "10px",
            backgroundColor: props.colour
        }),
        buttonDiv: {
            display: "flex",
            justifyContent: "flex-end"
        },
        divider: {
            marginBottom: "5px"
        },
        authorName: {
            whiteSpace: "pre-wrap",
            width: "100%",
            textAlign: "left",
            color:"black"
        }
    })
);

const CreateCardComponent: React.FC<Props> = (props) => {
    const {columnId, authorName, acceptCallback, cancelCallback, isCardAnonymous} = props;

    const styles = useStyles(props)

    const authorNameComponent = useMemo(() => {
        return isCardAnonymous || authorName === undefined
            ? null
            : (
                <div>
                    <Typography className={styles.authorName}>
                        {authorName}
                    </Typography>
                    <Divider className={styles.divider}/>
                </div>
            )
    }, [isCardAnonymous, authorName, styles])

    return (
        <Paper className={styles.createCardContainer} elevation={2}>
            <div className={styles.createCardDiv}>
                {authorNameComponent}
                <CardEditorComponent columnId={columnId} onAccept={acceptCallback} onCancel={cancelCallback}/>
            </div>
        </Paper>
    )
}

export default CreateCardComponent



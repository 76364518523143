import Cookies from "js-cookie";

const EXPIRATION_TIME_DAYS = 7;

export class CookieManager {
    /**
     * @param cookieName
     * @param cookieValue
     * @param path
     *              Stored under path if defined, otherwise under current path
     * @private
     */
    private static setCookie(cookieName: string, cookieValue: string, path: string | undefined) {
        let options = {expires: EXPIRATION_TIME_DAYS, path: path}
        if (path === undefined)
            options = Object.assign({}, options, {path: window.location.pathname})

        Cookies.set(cookieName, cookieValue, options)
    }

    private static getCookie(cookieName: string): string | undefined {
        return Cookies.get(cookieName)
    }

    private static deleteCookie(cookieName: string) {
        return Cookies.remove(cookieName)
    }

    static setNameCookie(boardID: string, userName: string, path: string | undefined = undefined) {
        this.setCookie("username-" + boardID, userName, path)
    }

    static getNameCookie(boardID: string): string | undefined {
        return this.getCookie("username-" + boardID)
    }

    static setJWSToken(boardID: string, userName: string, token: string, path: string | undefined = undefined) {
        this.setCookie("jws-token-" + boardID + "-" + userName, token, path)
    }

    static getJWSToken(boardID: string, userName: string): string | undefined {
        return this.getCookie("jws-token-" + boardID + "-" + userName)
    }

    static deleteNameCookie(boardID: string) {
        this.deleteCookie("username-" + boardID)
    }

    static setHasAwsCookie(boardID: string, path: string | undefined = undefined) {
        this.setCookie("hasAws-" + boardID, "true", path)
    }

    static getHasAwsCookie(boardID: string): boolean {
        return this.getCookie("hasAws-" + boardID) !== undefined
    }

    static deleteHasAwsCookie(boardID: string) {
        this.deleteCookie("hasAws-" + boardID)
    }
}
import {Button, Tooltip} from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, {useMemo} from 'react'
import {debounce} from "../utilities";


interface Props {
    id: string,
    activeIcon: JSX.Element,
    inactiveIcon: JSX.Element,

    onToggle(isToggleActivatedNow: boolean): void,

    isActivated: boolean,
    tooltipMessage?: string,
    size?: string,
    margin?: string,
}

const useStyles = makeStyles((theme) =>
    createStyles({
        toggleComponentButton: (props: Props) => ({
            maxHeight: props.size ?? "25px",
            maxWidth: props.size ?? "25px",
            minHeight: props.size ?? "25px",
            minWidth: props.size ?? "25px",
            margin: props.margin ?? "0px",
            padding: "0px"
        }),
        toggleTooltip: {
            width: "100%",
            height: "100%"
        }
    })
);


const ToggleComponent: React.FC<Props> = (props) => {

    const styles = useStyles(props)
    const {id, activeIcon, inactiveIcon, onToggle, isActivated, tooltipMessage} = props

    const visibleIcon = useMemo(() => {
        return isActivated
            ? activeIcon
            : inactiveIcon
    }, [isActivated, activeIcon, inactiveIcon])

    const iconWithOptionalTooltip = useMemo(() => {
        return (tooltipMessage
            ? <Tooltip id={id + "-tooltip"}
                       className={styles.toggleTooltip}
                       title={tooltipMessage!}>{visibleIcon}</Tooltip>
            : visibleIcon)
    }, [id, styles.toggleTooltip, visibleIcon, tooltipMessage])

    return (
        <Button
            id={id}
            onClick={debounce(() => onToggle(!isActivated))}
            className={styles.toggleComponentButton}>
            {iconWithOptionalTooltip}
        </Button>
    )
}

export default ToggleComponent



import React, {useMemo} from 'react'
import {CircularProgress, Divider, Grid} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {BoardInfo} from "../../model/BoardInfo";
import BoardTileComponent from "./BoardTileComponent";
import {FormattedMessage} from "react-intl";

interface Props {
    listTitle: string,
    boardInfos: BoardInfo[],

    onTileClick(boardInfo: BoardInfo): void

    onDeleteClick?: (boardInfo: BoardInfo) => void
    boardsAreLoading: boolean
}

const useStyles = makeStyles(() =>
    createStyles({
        boardListContainer: {
            margin: "0px"
        },
        boardListHeading: {
            textAlign: "left"
        },
        divider: {
            marginBottom: "5px"
        }
    })
);

export const BoardTileListComponent: React.FC<Props> = (props: Props) => {
    const styles = useStyles()

    const {listTitle, boardInfos, onTileClick, onDeleteClick, boardsAreLoading} = props

    const sortedBoardInfos = useMemo(() => {
        return boardInfos.sort(
            (firstBoard, secondBoard) => {
                return secondBoard.creationTime.getTime() - firstBoard.creationTime.getTime()
            }
        )
    }, [boardInfos])

    const renderBoardTileComponents = (boardInfos: BoardInfo[]) => {
        return boardInfos.map((board:BoardInfo, index:number) => {
            return (
                <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
                    {<BoardTileComponent boardInfo={board} onClick={onTileClick} onDelete={onDeleteClick}/>}
                </Grid>
            )
        })
    }

    return (
        <div className={styles.boardListContainer}>
            <div>
                <h2 className={styles.boardListHeading}><FormattedMessage id={listTitle}/></h2>
            </div>
            <Divider className={styles.divider}/>
            {
                boardsAreLoading
                    ? (<div><CircularProgress color="secondary"/></div>)
                    : (
                        <Grid container spacing={1}>
                            {renderBoardTileComponents(sortedBoardInfos)}
                        </Grid>
                    )
            }
        </div>
    )
}

export default BoardTileListComponent
import React, {useRef, useState} from 'react'
import {Divider, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {BoardInfo} from "../../model/BoardInfo";
import ColumnInfo from "../../model/ColumnInfo";
import SmallButtonComponent from "../SmallButtonComponent";
import {Delete} from "@mui/icons-material";
import {useIsTextTruncated} from "../../hooks/UseIsTextTruncated";
import ColumnNameComponent from "./ColumnNameComponent.";
import ConfirmationDialog from "../ConfirmationDialog";
import {FormattedMessage, useIntl} from "react-intl";

interface Props {
    boardInfo: BoardInfo

    onClick(boardInfo: BoardInfo): void

    onDelete?: (boardInfo: BoardInfo) => void
}

const useStyles = makeStyles(() =>
    createStyles({
        boardTileContainer: {
            width: "100%",
            height: "300px",
        },
        boardTile: {
            height: "100%",
            padding: "1px",
            cursor: "pointer"
        },
        boardTileContent: {
            height: "100%",
            margin: "1rem"
        },
        divider: {
            margin: "5px 0"
        },
        boardName: {
            fontWeight: "bold",
            fontSize: "1.5rem",
            marginBottom: "0.5rem"
        },
        columnNameContainer: {
            height: "60%",
            overflowY: "auto",

        },
        columnName: {
            backgroundColor: "lightgrey",
            borderRadius: "5px",
            marginBottom: "5px",
            padding: "0.5rem"
        },
        header: {
            justifyContent: "flex-end",
            minHeight: "1.5rem",
            width: "1.5rem",
            alignItems: "flex-center"
        }
    })
);

export const BoardTileComponent: React.FC<Props> = (props: Props) => {
    const styles = useStyles()
    const {boardInfo, onClick, onDelete} = props
    const deletionColor = useTheme().palette.primary.main
    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)
    const onDeleteCancel = () => {
        setDeletionDialogOpen(false)
    }
    const intl = useIntl();

    const boardNameRef = useRef<HTMLElement>();
    const isBoardNameTruncated = useIsTextTruncated(boardNameRef, boardInfo.name)

    const deleteButtonComponent = () => {
        if (onDelete) {
            return (<SmallButtonComponent id={`board_tile_component-${boardInfo.boardId}-button-delete`}
                                          icon={<Delete fontSize={"small"}/>}
                                          onClick={() => setDeletionDialogOpen(true)}
                                          disabled={false}
                                          variant={"text"}
                                          size={"1rem"}
                                          margin={"2px"}
                                          hoverColor={deletionColor}
                />
            )
        } else {
            return null
        }
    }

    const renderColumnNames = (columns: ColumnInfo[]) => {
        return columns.map((column:ColumnInfo, index:number) => {
            return <ColumnNameComponent key={index} columnInfo={column}/>
        })
    }

    const renderDate = (date: Date) => {
        const timeFormatOptions = {
            hour: 'numeric' as 'numeric', minute: 'numeric' as 'numeric'
        }
        return <Typography noWrap={true}>
            <FormattedMessage
                id={"board-tile-created"}/>{": " + date.toLocaleDateString('de-DE', timeFormatOptions) + " Uhr"}
        </Typography>
    }

    return (
        <div className={styles.boardTileContainer}>
            <Paper className={styles.boardTile} onClick={() => onClick(boardInfo)}>
                <div className={styles.boardTileContent}>
                    <div style={{display: "flex"}}>
                        <span style={{width: "1.5rem"}}/>
                        <Tooltip title={boardInfo.name} disableHoverListener={!isBoardNameTruncated}
                                 placement={'top-start'}>
                            <Typography
                                style={{flexGrow: 1}}
                                noWrap={true}
                                className={styles.boardName}
                                component={"h6"}
                                ref={((instance: HTMLElement | null) => boardNameRef.current = instance ?? undefined)}
                            >{boardInfo.name}
                            </Typography>
                        </Tooltip>
                        <div className={styles.header}>
                            {deleteButtonComponent()}
                        </div>
                    </div>
                    {renderDate(boardInfo.creationTime)}
                    <Divider className={styles.divider}/>
                    <div className={styles.columnNameContainer}>
                        {renderColumnNames(boardInfo.columns)}
                    </div>
                </div>
            </Paper>
            {onDelete && <ConfirmationDialog open={deletionDialogOpen}
                                             onAccept={() => {
                                                 setDeletionDialogOpen(false);
                                                 onDelete(boardInfo)
                                             }}
                                             onCancel={onDeleteCancel}
                                             message={intl.formatMessage({id: "board-tile-confirmation-prompt-delete"})}
            />}
        </div>
    )
}

export default BoardTileComponent
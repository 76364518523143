import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Board} from "../model/Board";
import {BoardClient} from "../api/BoardClient";
import BoardPage from "./BoardPage";
import {CircularProgress} from "@mui/material";
import {ErrorPage} from "./ErrorPage";
import LoginPage from "./LoginPage";
import {CookieManager} from "../controller/CookieManager";
import {getCurrentUser, User} from "@2gether/frontend-library";
import jwtDecode from "jwt-decode";
import {JWSUser} from "../model/JWS";
import {UserAccessEnum, UserContext} from "../contexts/UserContext";
import {ACard} from "../model/Card";
import {Column} from "../model/Column";
import {LocaleContext} from "../contexts/LocaleContext";
import enLocale from "../locales/en.json"
import deLocale from "../locales/de.json"
import {IntlProvider} from "react-intl";

interface BoardLoadingPageParameters {
    boardId: string
}

const BoardLoadingPage: React.FC<any> = () => {
    const {boardId} = useParams<BoardLoadingPageParameters>()
    const [board, setBoard] = useState<Board | null>(null)
    const [columns, setColumns] = useState<Column[]>([])
    const [loading, setLoading] = useState(true)
    const {user, updateUser} = useContext(UserContext)
    const [name, setName] = useState<string | null>(() => {
        const cookieName = CookieManager.getNameCookie(boardId)
        if (cookieName === undefined)
            return null
        else
            return cookieName
    })

    const locale = useContext(LocaleContext).locale === 'en' ? 'en' : 'de';
    const messages = {
        en: enLocale,
        de: deLocale
    }
    useEffect(updateUser, [updateUser])
    useEffect(() => {
        board !== null ? setColumns(board.columns) : setColumns([])
    }, [board])

    const getNameFromAWS = async (user: User) => {
        CookieManager.setHasAwsCookie(boardId)
        let userName = user.givenName + " " + user.familyName
        CookieManager.setNameCookie(boardId, userName)
        if (CookieManager.getJWSToken(boardId, userName) === undefined) {
            const token = await BoardClient.getSingleton().createUserToken({boardId, id: user.username, name: userName})
            CookieManager.setJWSToken(boardId, userName, token)
        }
        setName(userName)
        return userName
    }

    const getNameFromCookie = async () => {
        if (CookieManager.getHasAwsCookie(boardId)) {
            CookieManager.deleteHasAwsCookie(boardId)
            CookieManager.deleteNameCookie(boardId)
            return null
        } else {
            let cookieName = CookieManager.getNameCookie(boardId)
            if (cookieName !== undefined) {
                if (CookieManager.getJWSToken(boardId, cookieName) === undefined)
                    return null;

                setName(cookieName)
                return cookieName
            } else {
                return null
            }
        }
    }

    const reloadBoard = useCallback((showLoading) => {

        if (showLoading) {
            setBoard(null)
            setLoading(true)
        }

        getCurrentUser().then(getNameFromAWS).catch(getNameFromCookie)
            .then(userName => {
                setName(userName);
                return BoardClient.getSingleton().getBoardWithId(boardId, CookieManager.getJWSToken(boardId, userName ?? ""));
            })
            .then((board) => {
                setBoard(board);
                setLoading(false);
            }).catch(() => {
            setLoading(false);
        })
        // eslint-disable-next-line
    }, [boardId, setLoading, setBoard])

    const addVote = (voterName: string, cardId: number, uniqueVotesForCard: number) => {

        const addVoteToCard = (card: ACard) => {
            if (card.id === cardId) {
                if (card.votesForCard !== -1) {
                    card.votesForCard++;
                }

                if (name === voterName) {
                    card.votesForCardFromUser++;
                }
                card.uniqueVotesForCard = uniqueVotesForCard;
            }
            return card;
        }
        const addVoteToColumn = (column: Column) => {
            column.cards.forEach(card => addVoteToCard(card))
            return column
        }

        setColumns(columns => columns.map(column => addVoteToColumn(column)))
    }

    const removeVote = (voterName: string, cardId: number, uniqueVotesForCard: number) => {
        const removeVoteFromCard = (card: ACard) => {
            if (card.id === cardId) {
                if (card.votesForCard !== -1) {
                    card.votesForCard--;
                }
                if (name === voterName && card.votesForCardFromUser > 0) {
                    card.votesForCardFromUser--;
                }

                card.uniqueVotesForCard = uniqueVotesForCard;
            }

            return card;
        }

        const removeVoteFromColumn = (column: Column) => {
            column.cards.forEach(card => removeVoteFromCard(card))
            return column
        }

        setColumns(columns => columns.map(column => removeVoteFromColumn(column)))
    }

    const addDownVote = (voterName: string, cardId: number, uniqueVotesForCard: number) => {

        const addDownVoteToCard = (card: ACard) => {
            if (card.id === cardId) {
                if (card.downVotesForCard !== -1) {
                    card.downVotesForCard++;
                }

                if (name === voterName) {
                    card.downVotesForCardFromUser++;
                }

                card.uniqueVotesForCard = uniqueVotesForCard;
            }
            return card;
        }
        const addDownVoteToColumn = (column: Column) => {
            column.cards.forEach(card => addDownVoteToCard(card))
            return column
        }

        setColumns(columns => columns.map(column => addDownVoteToColumn(column)))
    }

    const removeDownVote = (voterName: string, cardId: number, uniqueVotesForCard: number) => {
        const removeDownVoteFromCard = (card: ACard) => {
            if (card.id === cardId) {
                if (card.downVotesForCard !== -1) {
                    card.downVotesForCard--;
                }
                if (name === voterName && card.downVotesForCardFromUser > 0) {
                    card.downVotesForCardFromUser--;
                }

                card.uniqueVotesForCard = uniqueVotesForCard;
            }
            return card;
        }

        const removeDownVoteFromColumn = (column: Column) => {
            column.cards.forEach(card => removeDownVoteFromCard(card))
            return column
        }

        setColumns(columns => columns.map(column => removeDownVoteFromColumn(column)))
    }


    BoardClient.getSingleton().mutationFailureCallback = () => reloadBoard(false);

    useMemo(() => reloadBoard(true), [reloadBoard])

    if (board === null) {
        if (loading) {
            return <div>
                <CircularProgress color="secondary"/>
            </div>
        } else {
            return <IntlProvider locale={locale} messages={messages[locale]}><ErrorPage/></IntlProvider>
        }
    } else {
        if (name === null) {
            return <IntlProvider locale={locale} messages={messages[locale]}><LoginPage
                board={board}
                guestAccess={user.access === UserAccessEnum.NOT_AUTHORIZED}
                setWithToken={(jwsToken => {
                    const user: JWSUser = jwtDecode(jwsToken);
                    CookieManager.setNameCookie(boardId, user["user-name"])
                    CookieManager.setJWSToken(user["board-id"], user["user-name"], jwsToken)
                    setName(user["user-name"])
                })}/></IntlProvider>
        } else {
            return <IntlProvider locale={locale} messages={messages[locale]}>
                <BoardPage board={board} columns={columns}
                           reloadBoard={() => reloadBoard(false)}
                           name={name}
                           addVote={(voterName: string, cardId: number, uniqueVotesForCard: number) => addVote(voterName, cardId, uniqueVotesForCard)}
                           removeVote={(voterName: string, cardId: number, uniqueVotesForCard: number) => removeVote(voterName, cardId, uniqueVotesForCard)}
                           addDownVote={(voterName: string, cardId: number, uniqueVotesForCard) => addDownVote(voterName, cardId, uniqueVotesForCard)}
                           removeDownVote={(voterName: string, cardId: number, uniqueVotesForCard) => removeDownVote(voterName, cardId, uniqueVotesForCard)}/>
            </IntlProvider>
        }
    }
}

export default BoardLoadingPage
import jwtDecode from "jwt-decode";

export enum JWSUserType {
    ADMIN = "ADMIN",
    USER = "USER"
}

export interface JWSUser {
    'board-id': string,
    'user-id': string,
    'user-name': string,
    'user-type': string
}

export function userFromToken(token: string): JWSUser {
    return jwtDecode(token)
}
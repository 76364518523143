import React, {createContext, useCallback, useEffect, useState} from 'react'
import {getCurrentUser} from "@2gether/frontend-library";

export enum UserAccessEnum {
    AUTHORIZED = 'AUTHORIZED',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    GUEST = 'GUEST'
}

export interface RetroUser {
    access: UserAccessEnum
}

export interface UserContextValue {
    user: RetroUser
    updateUser(): void
}


export const UserContext = createContext<UserContextValue>({user: {access: UserAccessEnum.GUEST}, updateUser: () => {}})

const UserProvider : React.FC = ({ children }) => {

    const [user, setUser] = useState<RetroUser>({access: UserAccessEnum.GUEST})

    const checkUserAccess = useCallback(() => {
        getCurrentUser()
            .then(currentUser => {
                if (currentUser.isAuthorized){
                    setUser({access: UserAccessEnum.AUTHORIZED})
                }
                else if (!currentUser.isAuthorized){
                    setUser({access: UserAccessEnum.NOT_AUTHORIZED})
                }
            })
            .catch(() => setUser({access: UserAccessEnum.GUEST}));
    }, [setUser])

    const updateUser = useCallback(() => {
        checkUserAccess()
    }, [checkUserAccess])

    useEffect(() => {
        updateUser()
    }, [updateUser])

    const contextValue = {user: user, updateUser: checkUserAccess}
    return <>
        <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    </>
}

export default UserProvider
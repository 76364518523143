import { Divider, Paper } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

import React, { useContext, useMemo } from "react";
import { ACard, MergedCard, SimpleCard } from "../../model/Card";
import { SubCardsComponent } from "./SubCardsComponent";
import { Board, HighlightModes } from "../../model/Board";
import { Column } from "../../model/Column";
import { BoardClient } from "../../api/BoardClient";
import VotingGroupComponent from "./VotingGroupComponent";
import SimpleCardComponent from "./SimpleCardComponent";
import { DragStates } from "../Board/ColumnLayoutComponent";
import {
  setErrorMessageContext,
  userTokenContext,
} from "../../pages/BoardPage";
import HighlightingCardComponent from "./HighlightingCardComponent";

interface Props {
  id: string;
  card: ACard;
  cardIndex: number;
  votesVisible: boolean;
  votingVisible: boolean;
  downVotingVisible: boolean;
  board: Board;
  column: Column;
  colour: string;
  currentDraggableId: string | null;
  dragActive: DragStates;
  editActive: boolean;
  setEditActive: (value: boolean) => void;
  sorted: boolean;
  votesUnlimited: boolean;
  userVotesSum: number;
  userDownVotesSum: number;
  value: boolean;
  isEditable: number;
  setIsEditable: (value: number) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: (props: Props) => ({
      width: "100%",
      // marginTop:"5px",
      textAlign: "left",
      marginTop: "5px",
      color: theme.palette.text.secondary,
      whiteSpace: "pre-wrap",
      paddingBottom: 1,
      backgroundColor: props.colour,
    }),
    dimmedPaper: {
      opacity: "80%",
    },
    fade: {
      opacity: "20%",
    },
    blurText: {
      filter: "blur(2px)",
      color: "black",
    },
    textColor: {
      color: "black",
    },
    highlightedPaperRed: {
      animation: "$glowRed 1s infinite",
    },
    "@keyframes glowRed": {
      "0%": {
        boxShadow: "0px 0px 10px 10px red",
      },
      "50%": {
        boxShadow: "0px 0px 10px 20px red",
      },
      "100%": {
        boxShadow: "0px 0px 10px 10px red",
      },
    },
    subCard: (props: Props) => ({
      padding: 8,
      margin: 4,
      // backgroundColor: "white",
      color: theme.palette.text.secondary,
      wordWrap: "break-word",
      backgroundColor: props.colour,
    }),
    cardContent: {
      padding: "15px",
      wordWrap: "break-word",
      marginBottom: "5px",
    },
    subcardContent: {
      wordWrap: "break-word",
    },
    authorName: {
      whiteSpace: "pre-wrap",
      width: "100%",
      color: "black",
    },
    authorOfAnonymousCard: {
      whiteSpace: "pre-wrap",
      width: "100%",
      fontStyle: "italic",
      "&:after": { content: '" [anonym]"' },
    },
    draggableHeader: {
      padding: "15px",
      marginBottom: "5px",
      backgroundColor: `rgba(0, 0, 0, 0.25)`,
      borderRadius: "5px",
    },
    hidden: {
      height: "1em",
      padding: "15px",
      backgroundColor: `rgba(0, 0, 0, 0.12)`,
      borderRadius: "5px",
    },
    divider: {
      marginBottom: "5px",
    },
    icon: {
      float: "right",
      fontSize: "20px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-end",
      minHeight: "1.5rem",
    },
  })
);

const useStylesCardColorHighlight = (props: Props) => {
  //this is not how makeStyles should be used
  //unfortunately this seems to be the only way to give parameters to keyframes
  let color = props.colour;
  let [, , , alpha] = color.match(/[\d.]+/g)!;
  if (!isNaN(+alpha) && alpha !== null) {
    alpha = "" + Math.min(+alpha * 2, 1);
    color = color.replace(/[\d.]+\)$/g, `${alpha})`);
  }
  return makeStyles((theme) =>
    createStyles({
      highlightedPaperCardColor: {
        animation: "$glowCC 1s infinite",
      },
      "@keyframes glowCC": {
        "0%": {
          boxShadow: `0px 0px 10px 10px ${color}`,
        },
        "50%": {
          boxShadow: `0px 0px 10px 20px ${color}`,
        },
        "100%": {
          boxShadow: `0px 0px 10px 10px ${color}`,
        },
      },
    })
  )();
};

const VotingComponent = (props: Props) => {
  const {
    votingVisible,
    downVotingVisible,
    votesVisible,
    card,
    board,
    editActive,
    votesUnlimited,
    userVotesSum,
    userDownVotesSum,
  } = props;
  const styles = useStyles(props);
  const setErrorMessage = useContext(setErrorMessageContext);

  const userToken = useContext(userTokenContext) ?? "";

  const addVote = () => {
    BoardClient.getSingleton()
      .voteForCard(board, card.id, userToken)
      .catch(setErrorMessage);
  };

  const addDownVote = () => {
    BoardClient.getSingleton()
      .downVoteForCard(board, card.id, userToken)
      .catch(setErrorMessage);
  };

  const removeVote = () => {
    BoardClient.getSingleton()
      .removeVoteForCard(board, card.id, userToken)
      .catch(setErrorMessage);
  };

  const removeDownVote = () => {
    BoardClient.getSingleton()
      .removeDownVoteForCard(board, card.id, userToken)
      .catch(setErrorMessage);
  };

  return (
    <>
      {(votingVisible || votesVisible) && (
        <>
          <Divider
            className={styles.divider}
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "5px",
            }}
          />
          <VotingGroupComponent
            id={`card_component_${card.id}-button`}
            allVotes={card.votesForCard}
            allDownVotes={card.downVotesForCard}
            addVote={addVote}
            addDownVote={addDownVote}
            removeVote={removeVote}
            removeDownVote={removeDownVote}
            votingVisible={votingVisible}
            downVotingVisible={downVotingVisible}
            votesVisible={votesVisible}
            userVotes={card.votesForCardFromUser}
            userDownVotes={card.downVotesForCardFromUser}
            editActive={editActive}
            userHasVotedForCard={card.votesForCardFromUser > 0}
            userHasDownVotedForCard={card.downVotesForCardFromUser > 0}
            votesUnlimited={votesUnlimited}
            multiVoteAllowed={board.multiVoteAllowed}
            maxVotes={board.maxVotes}
            userVotesSum={userVotesSum}
            userDownVotesSum={userDownVotesSum}
            uniqueVotesForCard={card.uniqueVotesForCard}
          />
        </>
      )}
    </>
  );
};

interface MergedCardProps extends Props {
  card: MergedCard;
  provided: DraggableProvided;
  setHighlightedCardId: (value: number) => void;
}

export const MergedCardComponent: React.FC<MergedCardProps> = (props) => {
  const {
    column,
    provided,
    card,
    board,
    editActive,
    setHighlightedCardId,
  } = props;
  const styles = useStyles(props);

  return (
    <>
      <div // merged
        className={styles.draggableHeader}
        {...provided.dragHandleProps}
      >
        <HighlightingCardComponent
          card={card}
          board={board}
          editActive={editActive}
          setHighlightedCardId={setHighlightedCardId}
        />
      </div>
      {SubCardsComponent({ ...props, columnId: column.id, styles })}
    </>
  );
};

const CardComponent: React.FC<Props> = (props) => {
  const {
    id,
    card,
    board,
    cardIndex,
    column,
    currentDraggableId,
    dragActive,
    sorted,
    editActive,
    setEditActive,
    value,
    isEditable,
    setIsEditable,
  } = props;

  const isHighlighted = card.id === board.highlightedCardId;
  const isDimmed = board.highlightedCardId !== -1 && !isHighlighted;
  const isBlurred = isDimmed && board.highlightMode === HighlightModes.FadeOut;
  const styles = useStyles(props);
  const stylesCarColorHighlight = useStylesCardColorHighlight(props);

  const userToken = useContext(userTokenContext) ?? "";
  let highlightClass: string = "";
  if (isHighlighted) {
    if (board.highlightMode === HighlightModes.Red) {
      highlightClass = styles.highlightedPaperRed;
    } else if (board.highlightMode === HighlightModes.CardColor) {
      highlightClass = stylesCarColorHighlight.highlightedPaperCardColor;
    }
  } else if (isDimmed) {
    highlightClass =
      board.highlightMode === HighlightModes.FadeOut
        ? styles.fade
        : styles.dimmedPaper;
  }
  const draggableId = "card-" + card.id;
  const mergeDroppableActive =
    dragActive === DragStates.simpleCard &&
    sorted &&
    currentDraggableId !== draggableId;

  const mergeDroppable = useMemo(() => {
    return (
      <Droppable
        droppableId={"merge-" + column.id + "-" + card.id}
        isDropDisabled={!mergeDroppableActive}
        type={"CARDS"}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          return (
            <div
              {...provided.droppableProps}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                border: snapshot.isDraggingOver ? "dashed blue" : undefined,
                boxSizing: "border-box",
                zIndex: !mergeDroppableActive ? -1 : undefined,
              }}
              ref={provided.innerRef}
            >
              <div style={{ display: "none" }}>{provided.placeholder}</div>
            </div>
          );
        }}
      </Droppable>
    );
  }, [card, mergeDroppableActive, column, value]);

  const setErrorMessage = useContext(setErrorMessageContext);

  const setHighlightedCardId = (value: number) => {
    BoardClient.getSingleton()
      .setHighlightedCardId(board, value, userToken)
      .catch(setErrorMessage);
  };

  return (
    <Draggable
      draggableId={draggableId}
      index={cardIndex}
      key={card.id}
      isDragDisabled={editActive || !board.showAllCards}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
          }}
          ref={provided.innerRef}
        >
          <Paper
            id={id}
            className={`${styles.paper} ${highlightClass}`}
            elevation={2}
            style={{
              border: snapshot.combineTargetFor ? "dashed blue" : undefined,
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            {card.type === "SimpleCard" ? (
              <SimpleCardComponent
                value={value}
                card={card as SimpleCard}
                provided={provided}
                styles={styles}
                isSubcard={false}
                isBlurred={isBlurred}
                columnId={column.id}
                board={board}
                editActive={editActive}
                setEditActive={setEditActive}
                setHighlightedCardId={setHighlightedCardId}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
              />
            ) : (
              MergedCardComponent({
                ...props,
                card: card as MergedCard,
                provided,
                setHighlightedCardId,
              })
            )}
            {VotingComponent(props)}
            {mergeDroppable}
          </Paper>
        </div>
      )}
    </Draggable>
  );
};

export default CardComponent;

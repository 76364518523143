import {TextField,} from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, {useState} from 'react'
import SmallButtonComponent from "../SmallButtonComponent";
import {Check, Close, Visibility, VisibilityOff} from "@mui/icons-material";
import ToggleComponent from "../ToggleComponent";
import {colorOptions} from "./CreateColumnComponent";
import ColorPalette from "./ColorPalette";
import {useIntl} from "react-intl";

interface Props {
    initialText?: string,
    color: string,
    initialAnonymous: boolean,

    onAccept(cardText: string, anonymous: boolean, color: string): void,

    onCancel(): void,

    changeColumnColor?(color: string): void
}

const useStyles = makeStyles((theme) =>

    createStyles({
        createCardContainer: {
            width: "100%",
            color: theme.palette.text.secondary,
        },
        inputDiv: {
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "3px"
        },
        buttonDiv: {
            display: "flex",
            justifyContent: "flex-end"
        }
    })
);

const ColumnEditorComponent: React.FC<Props> = (props) => {
    const {initialText, initialAnonymous, onAccept, onCancel, color, changeColumnColor} = props;
    const styles = useStyles(props)
    const [columnName, setColumnName] = useState<string>(initialText ?? "")
    const [saveButtonWasClicked, setSaveButtonWasClicked] = useState<boolean>(false)
    const columnTextFieldId = `create_column_component-text_field-new_column`
    const [anonymous, setAnonymous] = useState<boolean>(initialAnonymous)
    const [isShowColorPalette, setIsShowColorPalette] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(colorOptions.indexOf(color));

    const intl = useIntl();

    //  const colorPaletteRef = useRef<HTMLElement>(null);
    function saveEdit(columnText: string, anonymous: boolean) {
        let trimmedColumnName = columnText.trimEnd()
        setColumnName(trimmedColumnName)
        setSaveButtonWasClicked(true)
        onAccept(trimmedColumnName, anonymous, color)
    }

    function updateAnonymousToggleValue() {
        setAnonymous(!anonymous)
    }

    function changeColumnName(newName: string) {
        newName = newName.trimStart()
        setColumnName(newName)
    }

    const handleChangeColumnColor = (color: string, index: number) => {
        changeColumnColor && changeColumnColor(color)
        setSelectedIndex(index)
    }
    const renderColorOverlay = () => {
        return <ColorPalette setIsShowColorPalette={setIsShowColorPalette} isShowColorPalette={isShowColorPalette}
                             color={color} top={-4} left={-38} selectedIndex={selectedIndex}
                             handleChangeColumnColor={handleChangeColumnColor}/>
    }

    return (
        <div className={styles.createCardContainer}>
            <div className={styles.inputDiv}>
                <TextField
                    id={columnTextFieldId}
                    label={intl.formatMessage({id: "column-editor-column-name"})}
                    variant="standard"
                    value={columnName}
                    onChange={(event) => {
                        changeColumnName(event.target.value)
                    }}
                    fullWidth
                    autoFocus
                    multiline>
                </TextField>
                <ToggleComponent
                    id={"toggle_component-is_anonymous"}
                    activeIcon={<VisibilityOff style={{fill: "red"}}/>}
                    inactiveIcon={<Visibility style={{fill: "gray"}}/>}
                    onToggle={updateAnonymousToggleValue}
                    isActivated={anonymous}
                    tooltipMessage={"Bei Aktivierung werden die Namen von Autoren für diese Spalte nicht angezeigt."}
                    margin="0px 40px 1px 10px"
                />
                <div className={styles.buttonDiv}>
                    {renderColorOverlay()}
                    <SmallButtonComponent
                        id={`create_column_component-button-create_column`}
                        icon={<Check style={{fill: "rgba(255, 255, 255, 0.89)"}}/>}
                        onClick={() => {
                            saveEdit(columnName, anonymous)
                        }}
                        backgroundColor={"green"}
                        hoverBackgroundColor={"lightgreen"}
                        disabled={columnName === "" || saveButtonWasClicked}
                        margin={"2px"}

                    />
                    <SmallButtonComponent
                        id={`create_column_component-button-cancel`}
                        icon={<Close style={{fill: "rgba(255, 255, 255, 0.89)"}}/>}
                        onClick={onCancel}
                        backgroundColor={"red"}
                        hoverBackgroundColor={"pink"}
                        disabled={false}
                        margin={"2px"}
                    />
                </div>
            </div>

        </div>
    )
}

export default ColumnEditorComponent



import {Button, Grid} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import React from "react";
import {ArrowBack} from "@mui/icons-material";

export const NotFoundPage = () => {
    return (
        <Grid id={"message_on_page_not-found"}
              container
              direction={"column"}
              spacing={2}>
            <Grid item>
                <h2>404 Seite nicht gefunden</h2>
            </Grid>
            <Grid item>
                <SentimentVeryDissatisfiedIcon/>
            </Grid>
            <Grid item>
                <Button onClick={() => {window.location.pathname = "/"}}
                        color={"primary"}
                        startIcon={<ArrowBack/>}>
                    Zurück zur Boardübersicht
                </Button>
            </Grid>
        </Grid>
    )
}
import {FormControlLabel, FormGroup, Switch, TextField} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {Theme} from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useIntl} from "react-intl";

interface Props {
    votesLimited: boolean,
    votesLimitedEnable: () => void,
    editActive: boolean,
    maxVotes: number | undefined,
    sendMaxVotes: (value: number | undefined) => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        maxVotesContainer: {
            textAlign: "left",
        },
        maxVotesInput: {
            marginBottom: "20px",
        },
        maxVotesInputField: {
            padding: "0px 12px",
            width: "58px",
            boxSizing: "border-box"
        },
        circularProgress: {
            margin: 2
        }
    })
);

const LimitNumberOfVotesComponent: React.FC<Props> = (props) => {
    const {votesLimited, votesLimitedEnable, editActive, maxVotes, sendMaxVotes} = props;
    const styles = useStyles();
    const [maxVoteText, setMaxVoteText] = useState(maxVotes);
    const [timer, setTimer] = useState<number>(0);
    const intl = useIntl();

    const validateAndSetMaxVotes = (event: ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value.substr(0, 2);

        let value = isNaN(+text) ? maxVotes : Math.min(99, Math.max(0, +text));

        if (text === '') value = undefined;

        clearTimeout(timer)
        setMaxVoteText(value);
        setTimer(setTimeout(sendMaxVotes, 300, value))

        event.target.value = value === undefined ? '' : value.toString();
    };

    return (
        <FormGroup className={styles.maxVotesContainer}>
            <FormControlLabel
                control={
                    <Switch
                        id={'sidepanel-switch-enable_limit_for_votes'}
                        checked={votesLimited}
                        color={'primary'}
                        onChange={votesLimitedEnable}
                    />
                }
                label={intl.formatMessage({id: "limit-number-of-votes-component-limit-votes"})}
                disabled={editActive}
            />
            {votesLimited && (
                <FormControlLabel
                    className={styles.maxVotesInput}
                    control={
                        <TextField
                            id={'sidepanel-input-limit_for_votes'}
                            className={styles.maxVotesInputField}
                            value={maxVoteText}
                            onChange={validateAndSetMaxVotes}
                            size={"small"}
                            style={{textAlign: "center"}}
                            variant="standard"
                        />
                    }
                    label={intl.formatMessage({id: "limit-number-of-votes-component-max-votes"})}
                    disabled={editActive}
                />
            )}
        </FormGroup>
    )
}

export default LimitNumberOfVotesComponent
import {useEffect} from "react";
import {ActiveUserClient} from "../../api/ActiveUserClient";


export const ActiveUserPollingComponent: React.FC<{boardID: string, userToken: string, setUsers: (users: string[]) => any}>
    = ({boardID, userToken, setUsers}) => {
    useEffect(() => {
        const pollSettings = { keepPolling: true }
        ActiveUserClient.getSingleton()
            .subscribe(boardID, userToken, () => pollSettings.keepPolling, (update => {setUsers(update.users)}))
        return function cleanup() { pollSettings.keepPolling = false }
    }, [boardID, userToken, setUsers])
    return (<></>)
}
import {apiConfig} from "./ApiConfiguration";
import {ACTIVE_USER_URL} from "./urls";

export type UserList = {
    users: string[]
    version: number
}

export class ActiveUserClient {

    private readonly apiConfig = apiConfig({
        activeUsersUrl: ACTIVE_USER_URL
    });

    private static readonly _instance: ActiveUserClient = new ActiveUserClient();

    constructor() {}

    public static getSingleton(): ActiveUserClient {
        return ActiveUserClient._instance;
    }

    public async subscribe(boardId: string,
                           token: string,
                           keepPolling: () => boolean,
                           callback: (users: { users: string[] }) => any,
                           version?: number,
                           _backoffs = 0): Promise<void>
    {
        if (keepPolling()) {
            try {
                // console.info(`polling for ${token} on board ${boardId}`)
                let response = await fetch(this.apiConfig.urls.activeUsersUrl(`${boardId}/listen?version=${version ?? 0}`), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Retro-JWS-token': token,
                    }
                });

                if (response.status === 503 || response.status === 408) {
                    // on timeout
                    await this.subscribe(boardId, token, keepPolling, callback, version)
                } else if (response.status !== 200) {
                    // some other error
                    console.error(`ActiveUserClient: Unrecognized response status '${response.status}', retrying in ${2**_backoffs}s!`)
                    await new Promise(resolve => setTimeout(resolve, 2000**_backoffs)) // retry in 1 second
                    await this.subscribe(boardId, token, keepPolling, callback, version, Math.min(_backoffs+1, 5))
                } else {
                    // some update
                    const users = await response.json() as UserList;
                    callback(users)
                    await this.subscribe(boardId, token, keepPolling, callback, users.version)
                }
            }
            catch ( e ) {
                // something like cors etc
                console.error(`ActiveUserClient: An error occurred '${e}', retrying in ${2**_backoffs}s!`)
                await new Promise(resolve => setTimeout(resolve, 2000**_backoffs)) // retry in 1 second
                await this.subscribe(boardId, token, keepPolling, callback, version, Math.min(_backoffs+1, 5))
            }
        }
        else {
            // console.info(`stopped polling for ${token} on board ${boardId}`)
        }
    }
}
import React, {useState} from "react";

export const LocaleContext = React.createContext({
    locale: 'de',
    setLocale: (newLocale: string) => {
    }
})

const LocaleProvider: React.FC = ({children}) => {
    const [locale, setLocale] = useState("de");
    const value = {locale, setLocale};

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;
import { IconButton, TextField } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import React, { useCallback, useEffect, useState } from "react";
import ToggleComponent from "../ToggleComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import ColorPalette from "./ColorPalette";
import { useIntl } from "react-intl";

interface Props {
  id: string;
  columnName: string;
  columnIndex: number;
  color: string;
  isAnonymousToggleActive: boolean;
  takenColors: string[];

  onChange: (
    columnIndex: number,
    columnName: string,
    isAnonymousToggleActivated: boolean,
    color: string
  ) => void;

  removeColumn: (index: number) => void;
  columnAmount: number;
  updateColumns: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    createColumnContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  })
);

export const colorOptions = [
  "rgb(102, 255, 102)",
  "rgb(178,255,178)",
  "rgb(109,148,109)",
  "rgb(109,150,7)",
  "rgb(60,89,245)",
  "rgb(157,178,239)",
  "rgb(51, 153, 255)",
  "rgb(255, 102, 204)",
  "rgb(222,100,218)",
  "rgb(255, 153, 204)",
  "rgb(232,205,38)",
  "rgb(255,255,0)",
  "rgb(255,238,166)",
  "rgb(255, 153, 51)",
  "rgb(255,120,120)",
  "rgb(255,171,171)",
  "rgb(0,255,195)",
  "rgb(163,246,227)",
  "rgb(0,110,86)",
  "rgb(64,81,148)",
];
const CreateColumnComponent: React.FC<Props> = ({
  id,
  columnName,
  columnIndex,
  isAnonymousToggleActive,
  takenColors,
  onChange,
  color,
  columnAmount,
  removeColumn,
  updateColumns,
}) => {
  const ITEM_HEIGHT = 48;

  const styles = useStyles();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isShowColorPalette, setIsShowColorPalette] = useState<boolean>(false);
  const updateNameValue = (newName: string) => {
    let isToUpdate = columnName === "";
    onChange(columnIndex, newName, isAnonymousToggleActive, color);
    if (isToUpdate) updateColumns();
  };

  const updateAnonymousToggleValue = (newToggleState: boolean) => {
    onChange(columnIndex, columnName, newToggleState, color);
  };
  const updateColorValue = useCallback(
    (newColor: string) => {
      onChange(columnIndex, columnName, isAnonymousToggleActive, newColor);
    },
    [columnIndex, columnName, isAnonymousToggleActive, onChange]
  );
  const handleMenuItemClick = (color: string, index: number) => {
    setSelectedIndex(index);
    updateColorValue(colorOptions[index]);
  };

  useEffect(() => {
    const index = colorOptions.indexOf(color);
    setSelectedIndex(index);
  }, [color]);
  const renderColorOverlay = () => {
    return (
      <ColorPalette
        setIsShowColorPalette={setIsShowColorPalette}
        isShowColorPalette={isShowColorPalette}
        color={color}
        top={-18}
        left={-40}
        selectedIndex={selectedIndex}
        handleChangeColumnColor={handleMenuItemClick}
      />
    );
  };

  const intl = useIntl();
  return (
    <div className={styles.createColumnContainer}>
      {renderColorOverlay()}
      <TextField
        id={id + "-text_field-column_name"}
        type={"text"}
        value={columnName}
        onChange={(event) => {
          updateNameValue(event.target.value);
        }}
        placeholder={intl.formatMessage({ id: "create-board-new-column" })}
        required={false}
        variant={"outlined"}
        fullWidth={true}
      />
      {columnAmount > 1 && (
        <IconButton onClick={() => removeColumn(columnIndex)} size="large">
          <RemoveIcon />
        </IconButton>
      )}
      <ToggleComponent
        id={id + "-toggle_component-is_anonymous"}
        activeIcon={<VisibilityOff style={{ fill: "red" }} />}
        inactiveIcon={<Visibility style={{ fill: "gray" }} />}
        onToggle={updateAnonymousToggleValue}
        isActivated={isAnonymousToggleActive}
        tooltipMessage={
          "Bei Aktivierung werden die Namen von Autoren für diese Spalte nicht angezeigt."
        }
        margin="0px 0px 0px 10px"
      />
    </div>
  );
};

export default CreateColumnComponent;

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import {ThumbDownAltOutlined, ThumbUpAltOutlined} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SmallButtonComponent from '../SmallButtonComponent';
import {FormattedMessage} from "react-intl";

interface Props {
    id: string;
    votesVisible: boolean;
    addVote: () => void;
    addDownVote: () => void;
    removeVote: () => void;
    removeDownVote: () => void;
    votingVisible: boolean;
    downVotingVisible: boolean;
    userVotes: number;
    userDownVotes: number;
    allVotes: number;
    allDownVotes: number;
    userHasVotedForCard: boolean;
    userHasDownVotedForCard: boolean;
    editActive: boolean;
    votesUnlimited: boolean;
    multiVoteAllowed: boolean;
    maxVotes: number;
    userVotesSum: number;
    userDownVotesSum: number;
    uniqueVotesForCard: number;
}

const useStyles = makeStyles(() =>
    createStyles({
        buttonDiv: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginRight: '5px',
        },
        divider: {
            display: 'flex',
            flex: 1,
        },
        votes: {
            maxHeight: '20px',
            maxWidth: '30px',
            minWidth: '30px',
            margin: '0px 5px 2px 5px',
            color: 'black',
            textAlign: 'center',
            lineHeight: '20px',
        },
        ownVotes: {
            maxHeight: '20px',
            maxWidth: '30px',
            minWidth: '30px',
            margin: '0px 5px 2px 5px',
            color: 'black',
            textAlign: 'center',
            lineHeight: '20px',
        },
        sumVotes: {
            maxHeight: '20px',
            minWidth: '40px',
            margin: '0px 1px 2px 1px',
            color: 'black',
            textAlign: 'center',
            lineHeight: '20px',
        },
        votingSubgroupDownVotes: {
            maxWidth: '125px',
            minWidth: '90px',
            textAlign: 'left',
            alignItems: 'left',
            marginLeft: '12px',
            lineHeight: '20px'
        },
        votingSubgroupVotes: {
            maxWidth: '125px',
            minWidth: '90px',
            textAlign: 'right',
            alignItems: 'right',
            marginRight: '6px',
            lineHeight: '20px'
        },
    })
);

const VotingGroupComponent: React.FC<Props> = ({
                                                   id,
                                                   votesVisible,
                                                   votingVisible,
                                                   userVotes,
                                                   userDownVotes,
                                                   addVote,
                                                   addDownVote,
                                                   removeVote,
                                                   removeDownVote,
                                                   downVotingVisible,
                                                   allVotes,
                                                   allDownVotes,
                                                   userHasVotedForCard,
                                                   userHasDownVotedForCard,
                                                   editActive,
                                                   votesUnlimited,
                                                   multiVoteAllowed,
                                                   maxVotes,
                                                   userVotesSum,
                                                   uniqueVotesForCard,
                                                   userDownVotesSum
                                               }) => {
    const styles = useStyles();

    return (

        <div className={styles.buttonDiv}>
            <div className={styles.votingSubgroupDownVotes}>
                {votesVisible && downVotingVisible ? <span className={styles.votes}>{allDownVotes}</span> : null}
                {votingVisible && downVotingVisible &&
                ((votesUnlimited && !userHasDownVotedForCard) ||
                    (multiVoteAllowed && ((userDownVotesSum + userVotesSum) < maxVotes || votesUnlimited))) ? (
                    <span data-testid={'downvote-icon'}>
                        <SmallButtonComponent
                            id={id + '-add_down_vote'}
                            onClick={addDownVote}
                            icon={<ThumbDownAltOutlined style={{fontSize: '20px'}}/>}
                            disabled={editActive}
                            size={'20px'}
                            margin={'5px'}
                            variant={'text'}
                        />
                    </span>

                ) : null}
                {votingVisible && userHasDownVotedForCard && downVotingVisible ? (

                    <SmallButtonComponent
                        id={id + '-remove_down_vote'}
                        onClick={removeDownVote}
                        icon={<ClearIcon style={{fontSize: '20px'}}/>}
                        disabled={editActive}
                        size={'20px'}
                        margin={'5px'}
                        variant={'text'}
                    />

                ) : null}
                {multiVoteAllowed && downVotingVisible ? (
                    <span className={styles.ownVotes}>{'(' + userDownVotes + ')'}</span>
                ) : null}
            </div>

            <div className={styles.divider}/>

            {downVotingVisible && votesVisible ? (
                <span data-testid={'sum-icon'} className={styles.sumVotes}><FormattedMessage
                    id={String.fromCharCode(931)}/>{`: ${allVotes - allDownVotes}`}</span>
            ) : null}

            {uniqueVotesForCard >= 0 ? (
                <span data-testid={'uniquevote-icon'} className={styles.sumVotes}>&#x1F984;{`: ${uniqueVotesForCard}`}</span>
            ) : null}

            <div className={styles.divider}/>

            <div className={styles.votingSubgroupVotes}>
                {multiVoteAllowed ? (
                    <span className={styles.ownVotes}>{'(' + userVotes + ')'}</span>
                ) : null}
                {votingVisible && userHasVotedForCard ? (
                    <SmallButtonComponent
                        id={id + '-remove_vote'}
                        onClick={removeVote}
                        icon={<ClearIcon style={{fontSize: '20px'}}/>}
                        disabled={editActive}
                        size={'20px'}
                        margin={'5px'}
                        variant={'text'}
                    />
                ) : null}
                {votingVisible &&
                ((votesUnlimited && !userHasVotedForCard) ||
                    (multiVoteAllowed && ((userVotesSum + userDownVotesSum) < maxVotes || votesUnlimited))) ? (
                    <SmallButtonComponent
                        id={id + '-add_vote'}
                        onClick={addVote}
                        icon={<ThumbUpAltOutlined style={{fontSize: '20px'}}/>}
                        disabled={editActive}
                        size={'20px'}
                        margin={'5px'}
                        variant={'text'}
                    />
                ) : null}
                {votesVisible ? <span className={styles.votes}>{allVotes}</span> : null}
            </div>
        </div>

    );
};
export default VotingGroupComponent;

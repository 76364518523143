import {ACard} from "../../model/Card";
import {Board} from "../../model/Board";
import React, {useContext, useMemo} from "react";
import SmallButtonComponent from "../SmallButtonComponent";
import {Star, StarBorder} from "@mui/icons-material";
import {isVerifiedAdmin} from "../../controller/AdminVerificator";
import {userTokenContext} from "../../pages/BoardPage";

interface Props {
    card: ACard;
    board: Board;
    editActive: boolean
    setHighlightedCardId: (value: number) => void
}

const HighlightingCardComponent: React.FC<Props> = (props) => {
    const {
        card,
        board,
        editActive,
        setHighlightedCardId
    } = props;

    const userToken = useContext(userTokenContext)

    const isAdmin = useMemo(() => {
        return isVerifiedAdmin(userToken)
    }, [userToken])

        if (isAdmin){
        if (!(board.highlightedCardId === card.id)) {
            return (
                <SmallButtonComponent id={`card_component-${card.id}-button-highlight`}
                                      icon={<Star fontSize={"small"}/>}
                                      onClick={() => {
                                          setHighlightedCardId(card.id)
                                      }}
                                      disabled={editActive}
                                      variant={"text"}
                                      size={"1rem"}
                                      margin={"2px"}
                />
            )
        } else {
            return(
                <SmallButtonComponent id={`card_component-${card.id}-button-unhighlight`}
                                      icon={<StarBorder fontSize={"small"}/>}
                                      onClick={() => {
                                          setHighlightedCardId(-1)
                                      }}
                                      disabled={editActive}
                                      variant={"text"}
                                      size={"1rem"}
                                      margin={"2px"}
                />
            )
        }
    }
    else{
        return null
    }
}
export default HighlightingCardComponent
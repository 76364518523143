import {Button, TextField} from "@mui/material"
import React, {useState} from 'react'
import {debounce} from "../../utilities";

interface Props {
    signIn: (name: string) => void,
    styles: any
}

const GuestLoginComponent: React.FC<Props> = (props) => {
    const [name, setName] = useState<string>("")
    const [disabled, setDisabled] = useState<boolean>(true)
    const {signIn, styles} = props

    return (
        <div className={styles.parentDiv}>
            <div className={styles.contentDiv}>
                <h2 className={styles.retroHeading}>Als Gast einloggen</h2>
                <TextField
                    id="guest_login_component-text_area-name"
                    className={styles.textField}
                    label="Name"
                    value={name}
                    variant="outlined"
                    onChange={(event) => {
                        setName(event.target.value)
                        setDisabled(!event.target.value)
                    }}
                    fullWidth
                    inputProps={{maxLength: 50}}>
                </TextField>
                <div className={styles.retroStartDiv}>
                    <Button
                        id="guest_login_component-button-log_in_as_guest"
                        className={styles.button}
                        onClick={debounce(() => signIn(name))}
                        color={"primary"}
                        variant={"contained"}
                        disabled={disabled}>
                        Als Gast beitreten
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default GuestLoginComponent




import LensIcon from "@mui/icons-material/Lens";
import {colorOptions} from "./CreateColumnComponent";
import {ClickAwayListener, MenuItem, MenuList, Paper} from "@mui/material";
import React from "react";

interface IProps {
    setIsShowColorPalette: (isShowColorPalette: boolean) => void,
    isShowColorPalette: boolean,
    color: string,
    top: number,
    left: number,
    selectedIndex: number,
    handleChangeColumnColor: (color: string, colorIndex: number) => void
}

const ColorPalette = ({
                          setIsShowColorPalette,
                          isShowColorPalette,
                          color,
                          top,
                          left,
                          selectedIndex,
                          handleChangeColumnColor
                      }: IProps) => {
    return (
        <div style={{position: "relative"}}>
            <LensIcon
                data-testid={"color-picker-icon"}
                onClick={() => setIsShowColorPalette(!isShowColorPalette)}
                fontSize="large"
                style={{
                    position: "absolute",
                    top: top + "px",
                    left: left + "px",
                    fill: color ? color : colorOptions[selectedIndex],
                    cursor: "pointer",
                }}/>

            {isShowColorPalette &&
            <ClickAwayListener onClickAway={() => setIsShowColorPalette(false)}>
                <Paper style={{
                    zIndex: 99,
                    position: "absolute",
                    left: "-280px",
                    top: "38px",
                    width: "340px",
                    height: "200px",
                    marginLeft: "100px",
                    background: "#f5f6fa"
                }}
                       data-testid={"color palette"}
                >
                    <MenuList style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}} autoFocusItem={true}>
                        {colorOptions.map((option, index) => (
                            <MenuItem
                                data-testid={`color-item-${index}`}
                                key={option}
                                selected={index === selectedIndex}
                                onClick={() => handleChangeColumnColor(colorOptions[index], index)}
                            >
                                <LensIcon fontSize="large" style={{fill: colorOptions[index]}}/>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Paper>
            </ClickAwayListener>

            }
        </div>
    )
};

export default ColorPalette;
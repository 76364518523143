import React, {useState} from "react";
import {Grid, Popover, Switch, Theme, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DoneIcon from "@mui/icons-material/Done";
import {IReadinessComponent} from "./ReadinessComponent";

interface StyleProps {
    done: boolean,
    allUsersDone: boolean,
    label: string
}

const useStyles = makeStyles<Theme, StyleProps>(theme =>
    createStyles({
        userReadiness: {
            color: ({allUsersDone}) => allUsersDone ? 'green' : 'red',
            width: "36px",
            textAlign: "right",
            marginRight: "16px",
            marginLeft: "-6px"
        },
        doneIcon: {
            marginTop: 2,
            color: 'green',
            fontSize: "1rem",
            marginLeft: "-16px"
        },
        readinessLabel: {
            [theme.breakpoints.down('md')]: {width: "160px"},
            [theme.breakpoints.up('md')]: {width: ({label}) => (7 * label.length) + "px"},
        },
        placeholderReadinessToggle: {
            [theme.breakpoints.down('md')]: {marginRight: "101px"}
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        readyUserTypography: {
            color: 'green'
        },
        notReadyUserTypography: {
            color: 'red'
        }
    }),
);


export const ReadinessToggleComponent: React.FC<IReadinessComponent> = ({
                                                                            done,
                                                                            userToDone,
                                                                            onChange,
                                                                            label,
                                                                            id
                                                                        }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

    let readyUsersCount = 0
    userToDone.forEach((value: boolean) => {
        if (value) {
            readyUsersCount++
        }
    })
    const allUsersDone = readyUsersCount === userToDone.size

    const classes = useStyles({done: done, allUsersDone, label});

    const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const getReadyUsers = (): string[] => {
        const readyUsers: string[] = []
        userToDone.forEach((value: boolean, key: string) => {
            if (value) {
                readyUsers.push(key)
            }
        })
        return readyUsers
    }

    const getNotReadyUsers = (): string[] => {
        const notReadyUsers: string[] = []
        userToDone.forEach((value: boolean, key: string) => {
            if (!value) {
                notReadyUsers.push(key)
            }
        })
        return notReadyUsers
    }

    const sortAlphabetically = (arr: string[]) => {
        return arr.sort((a, b) => a.localeCompare(b))
    }

    const open = Boolean(anchorEl);

    return (
        <Grid container direction={"row"} alignItems={"center"} wrap={"nowrap"}
              className={classes.placeholderReadinessToggle}>
            <Grid item>
                <div className={classes.readinessLabel}>
                    <Typography align={"right"} variant="body2">{label}</Typography>
                </div>
            </Grid>
            <Grid item>
                <Switch id={id}
                        color={"primary"}
                        onChange={() => {
                            onChange(!done)
                        }}
                        checked={done}
                />
            </Grid>
            <Grid item>
                <Grid container wrap={"nowrap"}>
                    <Grid item>
                        <div
                            className={classes.userReadiness}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            onTouchStart={handlePopoverOpen}
                        >
                            <Typography variant="body2">
                                {readyUsersCount}/{userToDone.size}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        {done && <DoneIcon className={classes.doneIcon}/>}
                    </Grid>
                </Grid>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {sortAlphabetically(getReadyUsers()).map(user => <Typography
                        className={classes.readyUserTypography}>{user}</Typography>)}
                    {sortAlphabetically(getNotReadyUsers()).map(user => <Typography
                        className={classes.notReadyUserTypography}>{user}</Typography>)}
                </Popover>
            </Grid>
        </Grid>
    )
}
import {Alert, AppBar, Collapse, Grid, IconButton, Switch, Toolbar, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Board} from "../../model/Board";
import {ArrowBack} from "@mui/icons-material";
import {errorMessageContext, setErrorMessageContext, userTokenContext} from "../../pages/BoardPage";
import {RemainingVotesComponent} from "./RemainingVotesComponent";
import {ReadinessComponent} from "./ReadinessComponent";
import {BoardClient} from "../../api/BoardClient";
import {Theme} from "@mui/material/styles";
import SessionTimerComponent from "./SessionTimerComponent";
import {isVerifiedAdmin} from "../../controller/AdminVerificator";
import {debounce} from "../../utilities";
import {BoardTimerClient} from "../../api/BoardTimerClient";
import {Column} from "../../model/Column";
import {FormattedMessage} from "react-intl";

const HEADER_HEIGHT = "75px"
const ERROR_HEIGHT = "3rem"

interface Props {
    sorted: boolean
    setSorted: (value: boolean) => void
    board: Board
    editActive: boolean
    columns: Column[]
}

const useStyles = makeStyles<Theme, { headerHeight: string }>((theme) =>
    createStyles({
        gutters: {
            paddingLeft: 8,
            paddingRight: 8
        },
        sortingLabel: {
            [theme.breakpoints.down('md')]: {width: "160px"},
            [theme.breakpoints.up('md')]: {width: "155px"}
        },
        placeholderSortingToggle: {
            [theme.breakpoints.down('md')]: {marginRight: "146px"}
        },
        toolbarTitle: {
            paddingLeft: 16,
            paddingRight: 16,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },
        placeholderError: {
            minHeight: ERROR_HEIGHT,
        },
        header: {
            height: ({headerHeight}) => headerHeight
        },
        placeholderTimer: {
            [theme.breakpoints.only('lg')]: {marginLeft: "-13.65rem"}
        }
    })
);

const BoardHeaderComponent: React.FC<Props> = (props) => {
    const {sorted, setSorted, board, editActive, columns} = props;
    const appBarRef = useRef<any>();
    const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT)
    const styles = useStyles({headerHeight: headerHeight});
    const [cardsHaveVotes, setCardsHaveVotes] = useState(false);
    const userToken = useContext(userTokenContext);

    const showReadinessToggles = board.readinessRequested;
    const showSortingToggle = board.showVotes && cardsHaveVotes;
    const showTimer = board.timer.active;
    const showRemainingVotes = board.voting && board.votesLimited && board.maxVotes > 0 && board.maxVotes !== null;

    const appBarResizeObserver = useRef(new ResizeObserver((entries: ResizeObserverEntry[]) => {
        let appBar = entries[0].contentRect
        setHeaderHeight(`${appBar.height}px`)
    }))


    useEffect(() => {
        if (appBarResizeObserver.current && appBarRef.current)
            appBarResizeObserver.current.observe(appBarRef.current)

        if (appBarRef.current) {
            setHeaderHeight(appBarRef.current.offsetHeight);
            if (appBarResizeObserver.current) {
                appBarResizeObserver.current.observe(appBarRef.current)
            }
        }
        return function cleanup() {
            if (appBarRef.current && appBarResizeObserver.current) {
                appBarResizeObserver.current.unobserve(appBarRef.current)
            }

        }
    }, [])

    useEffect(
        () => {
            for (let i = 0; i < board.columns.length; i++) {
                for (let j = 0; j < board.columns[i].cards.length; j++) {
                    if (board.columns[i].cards[j].votesForCard > 0) {
                        setCardsHaveVotes(true)
                        return
                    }
                }
            }
            setCardsHaveVotes(false)
        }, [setCardsHaveVotes, cardsHaveVotes, columns]
    )

    const givenVotes = useMemo(() => {
        let count = 0;
        columns.forEach(col => col.cards.forEach(card => count += card.votesForCardFromUser));
        return count
    }, [columns])
    const givenDownVotes = useMemo(() => {
        let count = 0;
        columns.forEach(col => col.cards.forEach(card => count += card.downVotesForCardFromUser));
        return count
    }, [columns])

    const handleSortSwitch = useCallback(() => {
        setSorted(!sorted);
    }, [setSorted, sorted]);
    const errorMessage = useContext(errorMessageContext)
    const setErrorMessage = useContext(setErrorMessageContext)

    const deleteTimer =
        debounce(
            () => {
                BoardTimerClient.getSingleton().deleteBoardTimer(board, userToken ?? "").catch(setErrorMessage);
            }, 400, true)


    const renderBackButton = () => {
        return (
            <IconButton
                onClick={() => {
                    window.location.pathname = `/`
                }}
                color={"primary"}
                size="large">
                <ArrowBack/>
            </IconButton>
        );
    }

    const updateDone = useCallback((cardsDone, votingDone) => {
        BoardClient.getSingleton().editReadyUser(
            {sourceBoardVersionId: board.boardVersion, cardsReady: cardsDone, votingReady: votingDone},
            userToken ?? "",
            board
        );
    }, [board, userToken])

    return (
        <div>
            <div className={styles.header}>
                <AppBar color={"default"} elevation={2} ref={appBarRef}>
                    <Toolbar classes={{gutters: styles.gutters}}>
                        <Grid container columns={{xs: 24, md: 24, lg: 24, xl: 24}} alignItems={"flex-start"}
                              direction={"row"}
                              justifyContent={"space-between"}>
                            <Grid item xs={1} md={1} lg={1} xl={1}
                                  container
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  sx={{marginTop: "0.8rem"}}>
                                <Grid item>
                                    {renderBackButton()}
                                </Grid>
                            </Grid>
                            <Grid item xs={22} md={22} lg={22} xl={22}
                                  container
                                  alignItems={"flex-start"}
                                  justifyContent={"center"}
                            >
                                {showRemainingVotes &&
                                    <Grid item xs={24} md={24} lg={3} xl={3}
                                          container
                                          alignItems={"center"}
                                          justifyContent={"flex-start"}
                                          sx={{marginTop: "1.7rem"}}>
                                        <Grid item xs>
                                            <RemainingVotesComponent
                                                remainingVotes={board.maxVotes - givenVotes - givenDownVotes}
                                            />
                                        </Grid>
                                    </Grid>}
                                <Grid item xs
                                      container
                                      alignItems={"center"}
                                      justifyContent={"space-evenly"}
                                      direction={"row"}
                                >
                                    <Grid item xs={24} md={24}
                                          lg={board.name.length > 88 || (board.name.length > 48 && (showReadinessToggles || showSortingToggle || showTimer)) ? 10 : "auto"}
                                          xl={board.name.length > 88 || (board.name.length > 48 && (showReadinessToggles || showSortingToggle || showTimer)) ? 10 : "auto"}
                                          sx={{marginTop: "-0.2rem"}}
                                    >
                                        <h2 id={"board_page-heading-board_name"} className={styles.toolbarTitle}>
                                            {board.name}
                                        </h2>
                                    </Grid>
                                    {(showReadinessToggles || showSortingToggle) &&
                                        <Grid item xs={18} md={24} lg={"auto"} xl={"auto"}
                                              container
                                              alignItems={"center"}
                                              justifyContent={"center"}
                                              spacing={2}
                                              sx={{marginTop: "-0.8rem"}}>
                                            <Grid item>
                                                <ReadinessComponent
                                                    settings={{
                                                        ...board,
                                                        userStates: board.readyFlags
                                                    }
                                                    }
                                                    onChangeCards={value => updateDone(value, board.userIsDoneWithVotes)}
                                                    onChangeVote={value => updateDone(board.userIsDoneWithCards, value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                {showSortingToggle &&
                                                    <Grid container direction={"row"} alignItems={"center"}
                                                          wrap={"nowrap"}
                                                          className={styles.placeholderSortingToggle}>
                                                        <Grid item>
                                                            <div className={styles.sortingLabel}>
                                                                <Typography align={"right"} variant="body2" noWrap>
                                                                    <FormattedMessage
                                                                        id={'board-header-sort-by-votes'}/>
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <Switch id={"board_page-switch-sort"}
                                                                    color={"primary"}
                                                                    onChange={handleSortSwitch}
                                                                    checked={sorted}
                                                                    disabled={editActive}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>}
                                    {showTimer &&
                                        <Grid item xs={24} md={24}
                                              lg={showReadinessToggles && showSortingToggle && board.timer.name && board.name.length > 15 ? 24 : "auto"}
                                              xl={"auto"} container
                                              alignItems={"center"}
                                              justifyContent={"center"}
                                              className={(showReadinessToggles && showSortingToggle && board.timer.name && board.name.length > 15 && showRemainingVotes) ? styles.placeholderTimer : undefined}
                                              sx={{marginTop: "0.2rem"}}>
                                            <Grid item xs={"auto"}>
                                                <SessionTimerComponent
                                                    timerName={board.timer.name}
                                                    timestampEnd={board.timer.timerEndpoint}
                                                    userIsBoardAdmin={userToken === undefined ? false : isVerifiedAdmin(userToken)}
                                                    onTimerDeleteCallback={deleteTimer}/>
                                            </Grid>
                                        </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1}
                                  container
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  sx={{marginTop: "0.8rem"}}>
                                <Grid item>
                                    {props.children}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
            <Collapse in={errorMessage !== null}>
                <div className={styles.placeholderError}>
                    <Alert onClose={() => setErrorMessage(null)} severity={"error"}
                           style={{position: "fixed", left: "0", right: "0"}}>
                        {errorMessage}
                    </Alert>
                </div>
            </Collapse>
        </div>
    );
}

export default BoardHeaderComponent

import {Board} from "../model/Board";
import {DropResult} from "react-beautiful-dnd";
import {BoardClient} from "../api/BoardClient";
import {MoveColumnRequest} from "../model/request/MoveColumnRequest";
import {Column} from "../model/Column";

export const handleColumnMove = async (board: Board, result: DropResult, token: string)  => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const sourceColumnPosition = result.source.index;
    const destinationColumnPosition = result.destination.index;
    if (sourceColumnPosition === destinationColumnPosition)
        return;

    const req: MoveColumnRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceColumnPosition: sourceColumnPosition,
        targetColumnPosition: destinationColumnPosition,
    };
    await BoardClient.getSingleton().moveColumn(board.id, req, token);
}

export const moveColumnOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone: Board = JSON.parse(JSON.stringify(board));
    const sourceColumnPosition = result.source.index;
    const destinationColumnPosition = result.destination.index;

    if (sourceColumnPosition < destinationColumnPosition) {
        boardClone.columns = boardClone.columns.map<Column>((column) => {
            if (sourceColumnPosition < column.position && column.position <= destinationColumnPosition) {
                return {...column, position: column.position - 1}
            } else if (column.position === sourceColumnPosition) {
                return {...column, position: destinationColumnPosition}
            }
            return {...column}
        })
    } else {
        boardClone.columns = boardClone.columns.map<Column>((column) => {
            if (sourceColumnPosition > column.position && column.position >= destinationColumnPosition) {
                return {...column, position: column.position + 1}
            } else if (column.position === sourceColumnPosition) {
                return {...column, position: destinationColumnPosition}
            }
            return {...column}
        })
    }
    setBoard(boardClone);
};
import {Board} from "../model/Board";
import {Combine, DraggableLocation, DropResult} from "react-beautiful-dnd";
import {MergeCardRequest} from "../model/request/MergeCardRequest";
import {BoardClient} from "../api/BoardClient";
import {Column} from "../model/Column";
import {MoveCardRequest} from "../model/request/MoveCardRequest";
import {SplitCardRequest} from "../model/request/SplitCardRequest";
import {ACard, MergedCard, SimpleCard} from "../model/Card";

export const handleMerge = async (board: Board, result: DropResult, token: string) => {
    const sourceCardId = Number(result.draggableId.split("-")[1]);
    const combineCardId = Number(result.combine!!.draggableId.split("-")[1]);
    const req: MergeCardRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceCardId: sourceCardId,
        targetCardId: combineCardId
    };
    await BoardClient.getSingleton().mergeCard(board.id, req, token);
};

const getBoardColumnById = (board: Board, columnId: number): Column | null => {
    const col: Column | undefined = board.columns.find(column => column.id === columnId);
    return col ?? null;
};

const removeSourceFromBoard = (board: Board, source: DraggableLocation): ACard|null => {
    const sourceColumnId = Number(source.droppableId.split("-")[1]);
    const sourceColumn = getBoardColumnById(board, sourceColumnId);
    if (sourceColumn === null)
        return null;
    const toDelete = sourceColumn.cards[source.index];
    sourceColumn.cards.splice(source.index, 1);
    return toDelete;
};

const getDestinationNeighbors = (board: Board, destination: DraggableLocation) => {
    let pred = undefined;
    let succ = undefined;

    const destinationColumnId = Number(destination.droppableId.split("-")[1]);
    const destinationColumn = getBoardColumnById(board, destinationColumnId);
    if (destinationColumn === null) {
        return null;
    }
    const destinationColumnIndex = destination.index;
    if (destinationColumnIndex > 0)
        pred = destinationColumn.cards[destinationColumnIndex - 1].id;
    if (destinationColumnIndex < destinationColumn.cards.length)
        succ = destinationColumn.cards[destinationColumnIndex].id;
    return {pred, succ};
};

export const handleMoveToCard = async (board: Board, result: DropResult, token: string) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone = JSON.parse(JSON.stringify(board));
    removeSourceFromBoard(boardClone, result.source);

    const sourceCardId = Number(result.draggableId.split("-")[1]);
    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);

    const neighbors = getDestinationNeighbors(boardClone, result.destination);
    if (neighbors === null)
        return;

    const req: MoveCardRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceCardId: sourceCardId,
        targetColumnId: destinationColumnId,
        targetPredecessorCardId: neighbors.pred,
        targetSuccessorCardId: neighbors.succ
    };
    await BoardClient.getSingleton().moveCard(board.id, req, token);
};

export const handleMoveToColumn = async (board: Board, result: DropResult, token: string) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const sourceCardId = Number(result.draggableId.split("-")[1]);
    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);

    const req: MoveCardRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceCardId: sourceCardId,
        targetColumnId: destinationColumnId,
    };
    await BoardClient.getSingleton().moveCard(board.id, req, token);
};

export const handleSplitToCard = async (board: Board, result: DropResult, token: string) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;
    const sourceCardId = Number(result.draggableId.split("-")[2]);
    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);

    const neighbors = getDestinationNeighbors(board, result.destination);
    if (neighbors === null)
        return;

    const req: SplitCardRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceCardId: sourceCardId,
        targetColumnId: destinationColumnId,
        targetPredecessorCardId: neighbors.pred,
        targetSuccessorCardId: neighbors.succ
    };
    await BoardClient.getSingleton().splitCard(board.id, req, token);
};

export const handleSplitToColumn = async (board: Board, result: DropResult, token: string) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;
    const sourceCardId = Number(result.draggableId.split("-")[2]);
    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);

    const req: SplitCardRequest = {
        sourceBoardVersionId: board.boardVersion,
        sourceCardId: sourceCardId,
        targetColumnId: destinationColumnId,
    };
    await BoardClient.getSingleton().splitCard(board.id, req, token);
};

const getCombineTarget = (board: Board, combine: Combine): ACard|null => {
    const combineColumnId = Number(combine.droppableId.split("-")[1]);
    const combineColumn = getBoardColumnById(board, combineColumnId);
    const combineTargetId = Number(combine.draggableId.split("-")[1]);
    const combineTarget = combineColumn?.cards.find(card => card.id === combineTargetId);
    return combineTarget ?? null;
};

const merge = (target: ACard, source: ACard) => {
    const targetClone = JSON.parse(JSON.stringify(target));
    if ("childCards" in target && target.childCards === undefined)
        target.childCards = [];
    if ("childCards" in target && target.childCards.length === 0) {
        target.childCards.push(targetClone);
    }
    if ("childCards" in target) {
        target.childCards.push(source as SimpleCard);
    }
    if ("text" in target) {
        target.text = "";
    }
    if ("authorName" in target) {
        target.authorName = "";
    }
};

export const mergeOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.combine === null || result.combine === undefined)
        return;
    const boardClone = JSON.parse(JSON.stringify(board));
    const removed = removeSourceFromBoard(boardClone, result.source);
    if (removed === null)
        return;
    const target = getCombineTarget(boardClone, result.combine);
    if (target === null)
        return;
    merge(target, removed);
    setBoard(boardClone);
};

const getSplitParentCard = (board: Board, source: DraggableLocation, subCardDraggableId: string): ACard|null => {
    const parentCardColumnId = Number(subCardDraggableId.split("-")[1]);
    const parentCardColumn = getBoardColumnById(board, parentCardColumnId);
    const parentCardId = Number(source.droppableId.split("-")[1]);
    const parentCard = parentCardColumn?.cards.find(card => card.id === parentCardId);
    return parentCard ?? null;
};

const removeChildCard = (parentCard: MergedCard, index: number): ACard|null => {
    if (parentCard.childCards === undefined)
        return null;
    const removed = parentCard.childCards[index];
    parentCard.childCards.splice(index, 1);
    if (parentCard.childCards.length === 1) {
        Object.assign(parentCard, {
            ...(parentCard.childCards[0])
        })
    }
    return removed;
};

export const splitToColumnOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone = JSON.parse(JSON.stringify(board));

    const parentCard = getSplitParentCard(boardClone, result.source, result.draggableId) as MergedCard | null;
    if (parentCard === null)
        return;
    const removed = removeChildCard(parentCard, result.source.index);
    if (removed === null)
        return;

    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);
    const destinationColumn = getBoardColumnById(boardClone, destinationColumnId);
    destinationColumn?.cards?.push(removed);

    setBoard(boardClone);
};

export const moveToColumnOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone = JSON.parse(JSON.stringify(board));
    const removed = removeSourceFromBoard(boardClone, result.source);
    if (removed === null)
        return;

    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);
    const destinationColumn = getBoardColumnById(boardClone, destinationColumnId);
    destinationColumn?.cards?.push(removed);

    setBoard(boardClone);
};

export const moveToCardOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone = JSON.parse(JSON.stringify(board));
    const removed = removeSourceFromBoard(boardClone, result.source);
    if (removed === null)
        return;

    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);
    const destinationColumn = getBoardColumnById(boardClone, destinationColumnId);
    const destinationIndex = result.destination.index;
    destinationColumn?.cards?.splice(destinationIndex, 0, removed);

    setBoard(boardClone);
};

export const splitToCardOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    if (result.source === null || result.source === undefined)
        return;
    if (result.destination === null || result.destination === undefined)
        return;

    const boardClone = JSON.parse(JSON.stringify(board));

    const parentCard = getSplitParentCard(boardClone, result.source, result.draggableId) as MergedCard | null;
    if (parentCard === null)
        return;
    const removed = removeChildCard(parentCard, result.source.index);
    if (removed === null)
        return;

    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);
    const destinationColumn = getBoardColumnById(boardClone, destinationColumnId);
    const destinationIndex = result.destination.index;
    destinationColumn?.cards?.splice(destinationIndex, 0, removed);

    setBoard(boardClone);
};

const sortMergeResultToMergeResult = (result: DropResult): DropResult|null => {
    if (result.destination === null || result.destination === undefined)
        return null;

    const destinationColumnId = Number(result.destination.droppableId.split("-")[1]);
    const destinationCardId = Number(result.destination.droppableId.split("-")[2]);
    return {
        ...result,
        combine: {
            draggableId: "card-" + destinationCardId,
            droppableId: "col-" + destinationColumnId
        }
    }
}

export const handleSortedMerge = async (board: Board, result: DropResult, token: string) => {
    const mergeResult = sortMergeResultToMergeResult(result);
    if (mergeResult === null)
        return;
    await handleMerge(board, mergeResult, token);
};

export const sortedMergeOptimistically = (board: Board, result: DropResult, setBoard: (board: Board) => void) => {
    const mergeResult = sortMergeResultToMergeResult(result);
    if (mergeResult === null)
        return;
    mergeOptimistically(board, mergeResult, setBoard);
};

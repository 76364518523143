import { SimpleCard } from "../../model/Card";
import { DraggableProvided } from "react-beautiful-dnd";
import React, { useContext, useMemo } from "react";
import { Divider, Typography } from "@mui/material";
import SmallButtonComponent from "../SmallButtonComponent";
import { Delete, Edit } from "@mui/icons-material";
import { isVerifiedAdmin } from "../../controller/AdminVerificator";
import CardEditorComponent from "./CardEditorComponent";
import { BoardClient } from "../../api/BoardClient";
import { Board } from "../../model/Board";
import { debounce } from "../../utilities";
import {
  handleConfirmationDialogPropsContext,
  setErrorMessageContext,
  userTokenContext,
} from "../../pages/BoardPage";
import HighlightingCardComponent from "./HighlightingCardComponent";

interface Props {
  card: SimpleCard;
  board: Board;
  provided: DraggableProvided | null;
  styles: any;
  isSubcard: boolean;
  isBlurred: boolean;
  columnId: number;
  editActive: boolean;
  setEditActive: (value: boolean) => void;
  setHighlightedCardId: (value: number) => void;
  value?: boolean;
  isEditable: number;
  setIsEditable: (value: number) => void;
}

const SimpleCardComponent: React.FC<Props> = (props) => {
  const {
    card,
    provided,
    styles,
    isSubcard,
    isBlurred,
    columnId,
    board,
    editActive,
    setEditActive,
    setHighlightedCardId,
    value,
    isEditable,
    setIsEditable,
  } = props;

  const userToken = useContext(userTokenContext);

  const isAdmin = useMemo(() => {
    return isVerifiedAdmin(userToken);
  }, [userToken]);
  const handleConfirmationDialogProps = useContext(
    handleConfirmationDialogPropsContext
  );
  const setErrorMessage = useContext(setErrorMessageContext);

  const onDeleteCancel = () => {
    handleConfirmationDialogProps({
      open: false,
      message: "",
      onAccept: () => {},
      onCancel: () => {},
    });
  };

  const onDeleteAccept = () => {
    BoardClient.getSingleton()
      .deleteCard(board, card.id, userToken!)
      .catch((err) => {
        setErrorMessage(err);
      });
    onDeleteCancel();
  };

  const handleDeleteButtonClick = () => {
    handleConfirmationDialogProps({
      open: true,
      message: "Möchten Sie diese Karte endgültig löschen?",
      onAccept: onDeleteAccept,
      onCancel: onDeleteCancel,
    });
  };

  const authorComponent = () => {
    if (card.type === "SimpleCard" && card.authorName !== "") {
      let authorStyle = styles.authorName;
      if (card.anonymous) {
        authorStyle = styles.authorOfAnonymousCard;
      }
      return (
        <Typography
          className={`${authorStyle} ${isBlurred ? styles.blurText : ""}`}
        >
          {card.authorName}
        </Typography>
      );
    } else {
      return null;
    }
  };

  const switchInactiveAuthorComponent = () => {
    if (card.owned) {
      let authorStyle = styles.authorName;
      if (card.anonymous) {
        authorStyle = styles.authorOfAnonymousCard;
      }
      return (
        <Typography
          className={`${authorStyle} ${isBlurred ? styles.blurText : ""}`}
        >
          {card.authorName}
        </Typography>
      );
    }
  };

  const editButtonComponent = () => {
    if ((card.owned || isAdmin) && isEditable === -1) {
      return (
        <SmallButtonComponent
          id={`card_component-${card.id}-button-edit`}
          icon={<Edit fontSize={"small"} />}
          onClick={() => {
            setEditActive(true);
            setIsEditable(card.id);
          }}
          disabled={editActive}
          variant={"text"}
          size={"1rem"}
          margin={"2px"}
        />
      );
    } else {
      return null;
    }
  };

  const deleteButtonComponent = () => {
    if (card.owned || isAdmin) {
      return (
        <SmallButtonComponent
          id={`card_component-${card.id}-button-delete`}
          icon={<Delete fontSize={"small"} />}
          onClick={debounce(handleDeleteButtonClick)}
          disabled={editActive}
          variant={"text"}
          size={"1rem"}
          margin={"2px"}
        />
      );
    } else {
      return null;
    }
  };

  function saveCardEdit(cardText: string) {
    BoardClient.getSingleton()
      .editCard(
        {
          sourceBoardVersionId: board.boardVersion,
          text: cardText,
        },
        board.id,
        card.id,
        userToken!
      )
      .catch((err) => {
        setErrorMessage(err);
      })
      .finally(() => {
        setIsEditable(-1);
        setEditActive(false);
      });
  }

  const headerComponent = () => {
    if (
      authorComponent() === null &&
      editButtonComponent() === null &&
      deleteButtonComponent() === null
    ) {
      return null;
    }

    if (board.multiVoteAllowed) {
      return (
        <div>
          <div className={styles.header}>
            {authorComponent() || (!value && switchInactiveAuthorComponent())}
            {isSubcard || (
              <HighlightingCardComponent
                card={card}
                board={board}
                editActive={editActive}
                setHighlightedCardId={setHighlightedCardId}
              />
            )}
            {editButtonComponent()}
            {deleteButtonComponent()}
          </div>
          <Divider className={styles.divider} />
        </div>
      );
    } else {
      return (
        <div>
          <div className={styles.header}>
            {authorComponent() || (!value && switchInactiveAuthorComponent())}

            {isSubcard || (
              <HighlightingCardComponent
                card={card}
                board={board}
                editActive={editActive}
                setHighlightedCardId={setHighlightedCardId}
              />
            )}
            {editButtonComponent()}
            {deleteButtonComponent()}
          </div>
          <Divider className={styles.divider} />
        </div>
      );
    }
  };

  const handle = provided?.dragHandleProps ?? {};

  return (
    <>
      {card.hidden ? (
        <div className={styles.hidden} {...handle} /> // hidden simple
      ) : (
        <div // showing simple
          className={isSubcard ? styles.subcardContent : styles.cardContent}
          {...handle}
        >
          {headerComponent()}
          {isEditable === card.id ? (
            <CardEditorComponent
              initialText={card.text}
              onAccept={saveCardEdit}
              onCancel={() => {
                setIsEditable(-1);
                setEditActive(false);
              }}
              columnId={columnId}
            />
          ) : (
            <div className={isBlurred ? styles.blurText : styles.textColor}>
              {card.text}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(SimpleCardComponent);

import {Box, Button, Grid, Tooltip, Typography} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Column} from "../../model/Column";
import AddIcon from "@mui/icons-material/Add";
import CreateCardComponent from "../Cards/CreateCardComponent";
import {BoardClient} from "../../api/BoardClient";
import {Board} from "../../model/Board";
import CardComponent from "../Cards/CardComponent";
import {
    DraggableProvidedDragHandleProps,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "react-beautiful-dnd";
import {Delete, Edit, VisibilityOff} from "@mui/icons-material";
import {DragStates} from "../Board/ColumnLayoutComponent";
import {isVerifiedAdmin} from "../../controller/AdminVerificator";
import SmallButtonComponent from "../SmallButtonComponent";
import {debounce} from "../../utilities";
import ColumnEditorComponent from "./ColumnEditorComponent";
import {handleConfirmationDialogPropsContext, setErrorMessageContext, userTokenContext} from "../../pages/BoardPage";
import {useIsTextTruncated} from "../../hooks/UseIsTextTruncated";
import {MergedCard, SimpleCard} from "../../model/Card";
import {useIntl} from "react-intl";

export const columnColours = [
    [0, 0, 255], [0, 255, 0],
    [0, 255, 255], [255, 0, 0],
    [255, 0, 255], [255, 255, 0],
    [0, 0, 126], [0, 126, 0],
    [0, 126, 126], [126, 0, 0],
]

interface Props {
    board: Board,
    column: Column,
    name: string,
    votesVisible: boolean,
    votingVisible: boolean,
    downVotingVisible: boolean,
    dragActive: DragStates,
    editActive: boolean,
    value: boolean,

    setEditActive(value: boolean): void,

    sorted: boolean,
    currentDragSourceColumn: number | null,
    currentDraggableId: string | null,
    columnDragHandle: DraggableProvidedDragHandleProps | undefined,
    isDeletable: boolean,
    votesUnlimited: boolean,
    userVotesSum: number,
    userDownVotesSum: number
    isCardEditable: number
    setIsCardEditable: (value: number) => void
}

const useStyles = makeStyles((theme) =>

    createStyles({
        columnDiv: {
            whiteSpace: "pre-wrap",
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            borderRadius: "4px",
            width: "100%",
            height: "100%"
        },
        columnHeader: {
            margin: "4px 0px",
            whiteSpace: "pre-wrap",
            borderBottom: "solid 1px gray",
            width: "100%",
            display: "flex",
            textAlign: "left",
            float: "left"
        },
        columnTitle: {
            whiteSpace: "pre-wrap",
            gridColumn: "2 / 3",
            width: "100%",
        },
        headerButtonDiv: {
            gridColumn: "3 / 4",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end"
        },
        columnEditorComponent: {
            whiteSpace: "pre-wrap",
            gridColumn: "1 / -1",
            width: "100%"
        },
        gridItem: {
            width: "100%"
        },
        addCardButton: (props: Props) => ({
            backgroundColor: props.column.color
        })
    })
);

const ColumnComponent: React.FC<Props> = (props) => {
    const {
        column,
        board,
        name,
        columnDragHandle,
        dragActive,
        editActive,
        setEditActive,
        votesVisible,
        votingVisible,
        downVotingVisible,
        sorted,
        currentDraggableId,
        isDeletable,
        votesUnlimited,
        userVotesSum,
        userDownVotesSum,
        value,
        isCardEditable,
        setIsCardEditable

    } = props

    const [columnColor, setColumnColor] = useState(column.color);
    const [previousColumnColor, setPreviousColumnColor] = useState(column.color);
    const userToken = useContext(userTokenContext) ?? ""
    const isAdmin = useMemo(() => {
        return isVerifiedAdmin(userToken)
    }, [userToken])
    const [isEditable, setIsEditable] = useState(false)
    const styles = useStyles(props)
    const [showCreateCardComponent, setShowCreateCardComponent] = useState<boolean>(false)
    const setErrorMessage = useContext(setErrorMessageContext)
    const handleConfirmationDialogProps = useContext(handleConfirmationDialogPropsContext)

    const columnNameRef = React.useRef<HTMLElement>()
    const isColumnNameTruncated = useIsTextTruncated(columnNameRef, column.name)

    useEffect(() => setColumnColor(column.color), [column])

    const onDeleteCancel = () => {
        handleConfirmationDialogProps({
            open: false,
            message: "",
            onAccept: () => {
            },
            onCancel: () => {
            }
        })
    }

    const onDeleteAccept = () => {
        BoardClient.getSingleton()
            .deleteColumn(board, column.id, {sourceBoardVersionId: board.boardVersion}, userToken)
            .catch(setErrorMessage)
        onDeleteCancel()
    }

    const handleDeleteButtonClick = () => {
        handleConfirmationDialogProps({
            open: true,
            message: intl.formatMessage({id: "column-component-confirmation-prompt-delete"}),
            onAccept: onDeleteAccept,
            onCancel: onDeleteCancel
        })
    }
    const handleColumnColor = (color: string) => {
        setPreviousColumnColor(columnColor);
        setColumnColor(color);
    }
    const setCreateCardComponentState = useCallback((isShown: boolean) => {
        setShowCreateCardComponent(isShown)
        setEditActive(isShown)
    }, [setShowCreateCardComponent, setEditActive])

    const cardCreationComponent = useMemo(() => {
        return (
            <Grid item className={styles.gridItem}>
                <CreateCardComponent
                    colour={columnColor}
                    columnId={column.id}
                    authorName={name}
                    acceptCallback={(cardText: string) => {
                        BoardClient.getSingleton().addCard({
                                boardId: board.id,
                                columnId: column.id,
                                text: cardText,
                            }, userToken!
                        ).catch(err => {
                                setErrorMessage(err)
                            }
                        ).finally(() => {
                            setCreateCardComponentState(false)
                        })
                    }}
                    cancelCallback={() => {
                        setCreateCardComponentState(false)
                    }}
                    isCardAnonymous={
                        column.isAuthorAnonymous
                    }
                />
            </Grid>
        )
    }, [setCreateCardComponentState, board, column, styles.gridItem, name, columnColor, setErrorMessage, userToken, value])

    const intl = useIntl();

    const newCardButton = useMemo(() => {
        return (
            <Grid className={styles.gridItem} item>
                <Tooltip title={intl.formatMessage({id: "column-add-card"})}
                         id={`column_component-${column.id}-tooltip-add_card`}>
                    <Button
                        className={styles.addCardButton}
                        variant="outlined"
                        id={`column_component-${column.id}-button-add_card`}
                        onClick={() => {
                            setCreateCardComponentState(true)
                        }}
                        disabled={editActive}
                        fullWidth
                        style={{
                            backgroundColor: columnColor,
                            color: "rgba(0, 0, 0, 0.87)",
                            border: "1px solid rgba(0, 0, 0, 0.23)"
                        }}
                    >
                        <AddIcon/>
                    </Button>
                </Tooltip>
            </Grid>
        )
    }, [column.id, setCreateCardComponentState, styles.gridItem, styles.addCardButton, editActive, columnColor])

    const newCardComponent = useMemo(() => {
        return showCreateCardComponent ? cardCreationComponent : newCardButton
    }, [showCreateCardComponent, cardCreationComponent, newCardButton])


    const anonymousIcon = useMemo(() => {
        const tooltipMessage = "Bei Karten in dieser Spalte werden keine Autorennamen angezeigt."
        return column.isAuthorAnonymous ? <Tooltip title={tooltipMessage!}>{<VisibilityOff/>}</Tooltip> : null
    }, [column])

    const editButtonComponent = () => {
        if (isAdmin && !isEditable) {
            return (
                <SmallButtonComponent id={"col-" + column.id + "-button-edit"}
                                      icon={<Edit fontSize={"small"}/>}
                                      onClick={() => {
                                          setEditActive(true)
                                          setIsEditable(true)
                                      }}
                                      disabled={editActive}
                                      variant={"text"}
                                      size={"1rem"}
                                      margin={"2px"}
                />
            )
        } else {
            return null
        }
    }
    const editMode = () => {
        if (isEditable) {
            return (
                <div className={styles.columnEditorComponent}>
                    <ColumnEditorComponent
                        initialText={column.name}
                        onAccept={saveColumnEdit}
                        initialAnonymous={column.isAuthorAnonymous}
                        color={columnColor}
                        onCancel={() => {
                            setIsEditable(false)
                            setEditActive(false)
                            setColumnColor(previousColumnColor)
                        }}
                        changeColumnColor={handleColumnColor}
                    />
                </div>
            )
        } else return null
    }

    function saveColumnEdit(columnText: string, anonymous: boolean) {
        setIsEditable(false)
        setEditActive(false)
        BoardClient.getSingleton()
            .editColumn(board, column.id, {
                sourceBoardVersionId: board.boardVersion,
                text: columnText,
                anonymous,
                color: columnColor
            }, userToken)
            .catch(setErrorMessage)
    }

    const deleteButtonComponent = () => {
        if (isAdmin && isDeletable) {
            return (<SmallButtonComponent id={"col-" + column.id + "-button-delete"}
                                          icon={<Delete fontSize={"small"}/>}
                                          onClick={debounce(handleDeleteButtonClick)}
                                          disabled={editActive}
                                          variant={"text"}
                                          size={"1rem"}
                                          margin={"2px"}
                />
            )
        } else {
            return null
        }
    }


    const sortedByVotesSettings = (a: SimpleCard | MergedCard, b: SimpleCard | MergedCard) => {
        let sumVotesA = props.downVotingVisible ? a.votesForCard - a.downVotesForCard : a.votesForCard;
        let sumVotesB = props.downVotingVisible ? b.votesForCard - b.downVotesForCard : b.votesForCard;
        return -1 * (sumVotesA - sumVotesB) ||
            a.creationTime - b.creationTime ||
            a.id - b.id
    }

    const cards = useMemo(() => {
        let columnCards = [...column.cards];
        if (sorted) {
            columnCards.sort((a, b) => sortedByVotesSettings(a, b))
        }
        return (
            <Droppable droppableId={"col-" + column.id} isCombineEnabled={dragActive === DragStates.simpleCard}
                       isDropDisabled={sorted} type={"CARDS"}>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                    let border = undefined;
                    if (!sorted && (dragActive === DragStates.simpleCard || dragActive === DragStates.mergedCard)) {
                        border = "dashed grey";
                        if (snapshot.isDraggingOver) {
                            border = "dashed green";
                        }
                    }
                    return <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            border: border,
                            boxSizing: "border-box"
                        }}
                    >
                        <Grid container spacing={2}>
                            {columnCards.map((card, cardIndex) => {
                                    return (<Grid className={styles.gridItem} key={cardIndex} item>
                                        <CardComponent
                                            value={value}
                                            colour={columnColor}
                                            id={`column_component-${column.id}-container-card_component-${cardIndex}`}
                                            card={card}
                                            board={board}
                                            cardIndex={cardIndex}
                                            votesVisible={votesVisible}
                                            votingVisible={votingVisible}
                                            downVotingVisible={downVotingVisible}
                                            column={column}
                                            currentDraggableId={currentDraggableId}
                                            dragActive={dragActive}
                                            editActive={editActive}
                                            setEditActive={setEditActive}
                                            sorted={sorted}
                                            votesUnlimited={votesUnlimited}
                                            userVotesSum={userVotesSum}
                                            userDownVotesSum={userDownVotesSum}
                                            isEditable={isCardEditable}
                                            setIsEditable={setIsCardEditable}
                                        />
                                    </Grid>)

                                }
                            )}
                        </Grid>
                        {provided.placeholder}
                    </div>
                }
                }
            </Droppable>
        )
    }, [column, sorted, dragActive, styles.gridItem, columnColor, board, votesVisible, votingVisible, downVotingVisible, currentDraggableId, editActive, setEditActive, votesUnlimited, userVotesSum, userDownVotesSum, value])


    return (
        <div className={styles.columnDiv}>
            <Box flex={0}  {...columnDragHandle}>
                <Droppable droppableId={"header-" + column.id} type={"CARDS"}>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                        let border = undefined;
                        if (dragActive === DragStates.simpleCard || dragActive === DragStates.mergedCard) {
                            border = "dashed grey";
                            if (snapshot.isDraggingOver)
                                border = "dashed green";
                        }
                        return <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                border: border,
                                boxSizing: "border-box"
                            }}
                        >
                            <div className={styles.columnHeader}>
                                {isEditable ? editMode() : (
                                    <>
                                        <Tooltip title={props.column.name} disableHoverListener={!isColumnNameTruncated}
                                                 placement={'top-start'}>
                                            <Typography
                                                component="h6"
                                                id={`column_component-${column.position}-heading-column_name`}
                                                className={styles.columnTitle}
                                                noWrap
                                                ref={(instance: HTMLHeadingElement | null) => columnNameRef.current = instance ?? undefined}>
                                                {column.name}
                                            </Typography>
                                        </Tooltip>
                                        <div className={styles.headerButtonDiv}>
                                            {editButtonComponent()}
                                            {deleteButtonComponent()}
                                            {anonymousIcon}
                                        </div>
                                    </>
                                )}
                            </div>
                            {/*to prevent resizing*/}
                            <div style={{display: "none"}}>
                                {provided.placeholder}
                            </div>
                        </div>
                    }}
                </Droppable>
            </Box>
            <Box flex={0}>
                {newCardComponent}
            </Box>
            <Box flex={1} marginTop={2}>
                {cards}
            </Box>
        </div>

    )
}

export default ColumnComponent

import {useState, useEffect, useCallback, MutableRefObject} from 'react'

function isTextTruncated(textElement: HTMLElement | undefined) {
    if (!textElement) return false
    return textElement.clientWidth < textElement.scrollWidth
}

export function useIsTextTruncated(textElementRef: MutableRefObject<HTMLElement | undefined>, textContent: string) {

    const [isTruncated, setIsTruncated] = useState(false)

    const onResizeCallback = useCallback(
        () => setIsTruncated(isTextTruncated(textElementRef.current)),
        [textElementRef]
    )

    useEffect(() => {
        setIsTruncated(isTextTruncated(textElementRef.current))
        window.addEventListener('resize', onResizeCallback)
        return () => {
            window.removeEventListener('resize', onResizeCallback)
        }
    }, [onResizeCallback, textElementRef])

    return isTruncated
}
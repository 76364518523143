import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Board, HighlightModes} from "../../model/Board";
import LimitNumberOfVotesComponent from "./LimitNumberOfVotesComponent";
import {isVerifiedAdmin} from "../../controller/AdminVerificator";
import {userTokenContext} from "../../pages/BoardPage";
import {ActiveUserPollingComponent} from "./ActiveUserPollingComponent";
import {LocaleContext} from "../../contexts/LocaleContext";
import enLocale from "../../locales/en.json"
import deLocale from "../../locales/de.json"
import {FormattedMessage, IntlProvider, useIntl} from "react-intl";

const drawerWidth = 280;

interface Props {
    switchShowAuthorNames: () => void,
    voting: boolean,
    votingEnable: () => void,
    downVoting: boolean,
    downVotingEnable: () => void,
    showVotes: boolean,
    setShowVotes: () => void,
    showCardAuthors: boolean,
    setShowCardAuthors: () => void,
    editActive: boolean,
    multipleVotesAllowed: boolean,
    setMultipleVotesAllowed: () => void,
    handleResetDownVotes: () => void,
    maxVotes: number | undefined,
    votesLimited: boolean,
    votesLimitedEnable: () => void,
    playMusic: boolean,
    setPlayMusic: (b?: boolean) => void,
    shouldPlayMusic: boolean,
    setShouldPlayMusic: (value: boolean) => void,
    sendMaxVotes: (value: number | undefined) => void,
    board: Board,
    setShowCards: () => void,
    name: string,
    setReadinessRequested: () => void,
    setHighlightMode: (mode: HighlightModes) => void,
    startTimer: (duration: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-start',
        },
        drawerBody: {
            padding: '16px',
            alignItems: 'flex-start',
        },
        user: {
            margin: "0",
            marginBottom: "9px"
        },
        formControl: {
            margin: '3px',
            width: (drawerWidth / 5) * 4,
        },
        timer: {
            width: drawerWidth / 3,
            marginLeft: "9px",
            marginRight: "9px",
            marginBottom: "9px"
        },
        resetBtn: {
            fontSize: '10pt',
            marginTop: '10px'
        }
    })
);

export const SidePanel: React.FC<Props> = ({
                                               voting,
                                               votingEnable,
                                               downVoting,
                                               downVotingEnable,
                                               showVotes,
                                               setShowVotes,
                                               showCardAuthors,
                                               setShowCardAuthors,
                                               editActive,
                                               maxVotes,
                                               sendMaxVotes,
                                               multipleVotesAllowed,
                                               setMultipleVotesAllowed,
                                               votesLimited,
                                               votesLimitedEnable,
                                               playMusic,
                                               setPlayMusic,
                                               shouldPlayMusic,
                                               setShouldPlayMusic,
                                               board,
                                               setShowCards,
                                               startTimer,
                                               name,
                                               setReadinessRequested,
                                               setHighlightMode,
                                               switchShowAuthorNames,
                                               handleResetDownVotes
                                           }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [activeUsers, setActiveUsers] = useState<string[]>([name])
    const [selectedHighlightMode, setSelectedHighlightMode] = React.useState(board.highlightMode);
    const [timerName, setTimerName] = useState<string>("")
    const [timer, setTimer] = useState<number | undefined>(undefined)

    const locale = useContext(LocaleContext).locale === 'en' ? 'en' : 'de';
    const messages = {
        en: enLocale,
        de: deLocale
    }
    const intl = useIntl();

    const usertoken = useContext(userTokenContext)

    activeUsers.sort((a, b) => {
        const cmp = a.toLowerCase().localeCompare(b.toLowerCase(), "de") // ignore case
        return cmp !== 0 ? cmp : a.localeCompare(b, "de") // if matching string, sort with case
    });

    const validateAndSetTimerName = (event: ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value.substr(0, 20)
        setTimerName(text)
    };
    const validateAndSetTimer = (event: ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value.substr(0, 3);
        if (text === '-') {
            setTimer(undefined);
        } else {
            let value = isNaN(+text) ? timer : Math.min(999, +text);
            if (text === '') value = undefined;
            setTimer(value);
            event.target.value = value === undefined ? '' : value.toString();
        }
    };

    const handleHighlightModeChange = (event: SelectChangeEvent<unknown>) => {
        setHighlightMode(event.target.value as HighlightModes)
        setSelectedHighlightMode(event.target.value as HighlightModes);
    };

    const isAdmin = useMemo(() => {
        return isVerifiedAdmin(usertoken);
    }, [usertoken]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleStartTimer = () => {
        if (timer) {
            board.timer.name = timerName === null ? "" : timerName;
            startTimer(timer);
        }
    }
    const authorNamesFunction = () => {
        switchShowAuthorNames()
        setShowCardAuthors()
    }
    useEffect(() => {
        setPlayMusic(false)
        // eslint-disable-next-line
    }, [])
    return <IntlProvider locale={locale} messages={messages[locale]}>
        <ActiveUserPollingComponent boardID={board.id} userToken={usertoken ?? ""} setUsers={setActiveUsers}/>
        <IconButton
            id={'sidepanel-button-open'}
            color='inherit'
            aria-label='delete'
            onClick={handleDrawerOpen}
            className={clsx(open)}
            size="large">
            <MenuIcon/>
        </IconButton>
        <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='right'
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton id={'sidepanel-button-close'} onClick={handleDrawerClose} size="large">
                    <CloseIcon/>
                </IconButton>
            </div>

            <Divider/>
            {isAdmin && <>
                <Grid container direction={'column'} className={classes.drawerBody}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-show_cards'}
                                    checked={board.showAllCards}
                                    color={'primary'}
                                    onChange={setShowCards}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-reveal-card"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-inquire-readiness'}
                                    checked={board.readinessRequested}
                                    color={'primary'}
                                    onChange={setReadinessRequested}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-finish-card"})}
                        />
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container direction={'column'} className={classes.drawerBody}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-enable_multiple_votes'}
                                    checked={multipleVotesAllowed}
                                    color={'primary'}
                                    onChange={setMultipleVotesAllowed}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-multiple-votes"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid item data-testid={"downvote-toggle"}>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-enable_down-voting'}
                                    checked={downVoting}
                                    color={'primary'}
                                    onChange={downVotingEnable}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-down-votes"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid item>
                        <LimitNumberOfVotesComponent
                            votesLimited={votesLimited}
                            votesLimitedEnable={votesLimitedEnable}
                            editActive={editActive}
                            maxVotes={maxVotes}
                            sendMaxVotes={sendMaxVotes}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-enable_voting'}
                                    checked={voting}
                                    color={'primary'}
                                    onChange={votingEnable}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-voting-active"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-show_votes'}
                                    checked={showVotes}
                                    color={'primary'}
                                    onChange={setShowVotes}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-show-votes"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    id={'sidepanel-switch-show_author_names'}
                                    checked={showCardAuthors}
                                    defaultChecked={true}
                                    color={'primary'}
                                    onChange={authorNamesFunction}
                                />
                            }
                            label={intl.formatMessage({id: "side-panel-show-name"})}
                            disabled={editActive}
                        />
                    </Grid>
                    <Grid>
                        <FormControl variant="standard">
                            <InputLabel
                                id="sidepanel-highliht-select-modus"
                                className={classes.formControl}
                            ><FormattedMessage id={"side-panel-highlight"}/></InputLabel>
                            <Select
                                value={selectedHighlightMode}
                                onChange={handleHighlightModeChange}
                                className={classes.formControl}
                            >
                                <MenuItem value={HighlightModes.FadeOut}><FormattedMessage
                                    id={"side-panel-ausblenden"}/></MenuItem>
                                <MenuItem value={HighlightModes.Red}><FormattedMessage id={"side-panel-roter-rahmen"}/></MenuItem>
                                <MenuItem value={HighlightModes.CardColor}><FormattedMessage
                                    id={"side-panel-rahmen-in-kartenfarbe"}/></MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            onClick={handleResetDownVotes}
                            disabled={!downVoting}
                            size={"small"}
                            className={classes.resetBtn}
                            data-testid={'reset-button'}
                        >
                            <FormattedMessage id={"side-panel-reset-down-vote"}/>
                        </Button>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container direction={'column'} className={classes.drawerBody}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <TextField
                                    style={{marginLeft: "9px"}}
                                    id={'sidepanel-input-timer-name'}
                                    value={timerName}
                                    onChange={validateAndSetTimerName}
                                    label={intl.formatMessage({id: "side-panel-timer-name"})}
                                    variant="standard"
                                />
                            }
                            label={''}
                            disabled={editActive}
                        />
                    </Grid>
                    <FormControlLabel
                        control={
                            <TextField
                                id={'sidepanel-input-timer-value'}
                                value={timer}
                                onChange={validateAndSetTimer}
                                label={intl.formatMessage({id: "side-panel-duration"})}
                                className={classes.timer}
                                variant="standard"
                            />
                        }
                        label={intl.formatMessage({id: "side-panel-minute"})}
                        disabled={editActive}
                    />
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        onClick={handleStartTimer}
                        disabled={timer === undefined || timer <= 0}
                        size={"small"}
                    >
                        <FormattedMessage id={'side-panel-timer-start'}/>
                    </Button>
                </Grid>
                <Divider/>
            </>}
            <Grid container direction={'column'} className={classes.drawerBody}>
                {
                    isAdmin && (
                        <>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            id={'sidepanel-switch-play-music'}
                                            checked={playMusic}
                                            color={'primary'}
                                            onChange={() => setPlayMusic(!playMusic)}
                                        />
                                    }
                                    label={intl.formatMessage({id: "side-panel-play-music"})}
                                    disabled={editActive}
                                />
                            </Grid>
                            <Divider/>
                        </>
                    )
                }
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                id={'sidepanel-switch-audio'}
                                checked={shouldPlayMusic}
                                color={'primary'}
                                onChange={() => setShouldPlayMusic(!shouldPlayMusic)}
                            />
                        }
                        label={'Audio'}
                        disabled={editActive}
                    />
                </Grid>
            </Grid>
            <Divider/>
            <Grid container direction={'column'} className={classes.drawerBody}>
                <h3 className={classes.user}>
                    <FormattedMessage id="side-panel-active-user"/>
                </h3>
                {activeUsers.map((name) =>
                    <Typography>
                        {name}
                    </Typography>
                )}
            </Grid>
        </Drawer>
    </IntlProvider>;
};
export default React.memo(SidePanel);

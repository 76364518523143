import { Board} from '../model/Board';
import { ServerResponse } from '../model/ServerResponse';
import {withJsonContentHeader, withUserTokenHeader} from "./requestHelpers";
import {BoardTimerRequest} from '../model/BoardTimer';
import {BoardClient} from "./BoardClient";
import {apiConfig} from "./ApiConfiguration";
import {BOARD_URL} from "./urls";

export class BoardTimerClient {

  private readonly apiConfig = apiConfig({
    boardUrl: BOARD_URL
  });

  private static readonly _instance: BoardTimerClient = new BoardTimerClient();

  constructor() {}

  public static getSingleton(): BoardTimerClient {
    return BoardTimerClient._instance;
  }

  public async setBoardTimer(
    board: Board,
    timer: BoardTimerRequest,
    userToken: string
  ): Promise<ServerResponse<void>> {
    const url = this.apiConfig.urls.boardUrl( `${board.id}/setTimer`);
    const response = await fetch(url, {
      method: 'POST',
      headers: withJsonContentHeader(withUserTokenHeader(userToken)),
      body: JSON.stringify({
        active: timer.active,
        name: timer.name,
        duration: timer.duration,
        sourceBoardVersion: BoardClient.getSingleton().getSourceBoardVersion(board),
      }),
    });
    return BoardClient.getSingleton().resolveMutationResponse(response)
  }

  public async deleteBoardTimer(
    board: Board,
    userToken: string
  ): Promise<ServerResponse<void>> {
    const url = this.apiConfig.urls.boardUrl(`${board.id}/deleteTimer`);
    const response = await fetch(url, {
      method: 'POST',
      headers: withJsonContentHeader(withUserTokenHeader(userToken)),
      body: JSON.stringify({
        sourceBoardVersion: BoardClient.getSingleton().getSourceBoardVersion(board),
      }),
    });
    return BoardClient.getSingleton().resolveMutationResponse(response);
  }
}

import React, {useEffect, useState} from 'react'
import {Grid, Paper, Theme, Typography} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Delete} from "@mui/icons-material";
import SmallButtonComponent from "../SmallButtonComponent";

interface Props {
    timerName?: string
    timestampEnd: Date
    userIsBoardAdmin: boolean

    onTimerDeleteCallback(): void
}

interface TimeLeft {
    hours: number
    minutes: number
    seconds: number
}

const useStyles = makeStyles<Theme, { timerName: String | undefined }>(() =>

    createStyles({
        timer: {
            paddingBottom: '0.2rem',
            paddingTop: "0.2rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            margin: '1rem',
            background: 'inherit'
        },
        timerName: {
            fontSize: '1rem',
        },
        timerClock: {
            fontSize: '1rem'
        }
    })
);

const SessionTimerComponent: React.FC<Props> = (props: Props) => {

    const calculateTimeLeft = (timestampEnd: Date) => {
        const now = Date.now()
        let delta = Math.max(0, timestampEnd.getTime() - now)
        return {
            hours: Math.floor((delta / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((delta / 1000 / 60) % 60),
            seconds: Math.floor((delta / 1000) % 60)
        }
    }

    const addLeadingZero = (num: number) => {
        let numString = num.toString()
        return numString.length < 2 ? '0' + numString : numString
    }

    const timeLeftToString = (timeLeft: TimeLeft) => {
        const hourString = `${addLeadingZero(timeLeft.hours)}:`
        const minuteString = `${addLeadingZero(timeLeft.minutes)}:`
        const secondString = `${addLeadingZero(timeLeft.seconds)}`
        return hourString + minuteString + secondString
    }

    const {timerName, timestampEnd, userIsBoardAdmin, onTimerDeleteCallback} = props
    const styles = useStyles({timerName: timerName});
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timestampEnd))

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(timestampEnd));
        }, 1000)
        return () => clearTimeout(timer)
    })

    return (
        <Grid container>
            <Paper className={styles.timer}>
                <Grid container direction={"row"} alignItems={"center"} wrap={"nowrap"} spacing={1.2}>
                    {timerName &&
                    <Grid item xs={24} md={24} lg={24} xl={24}>
                        <Typography variant={'h3'} className={styles.timerName} noWrap>{timerName}</Typography>
                    </Grid>}
                    <Grid item container justifyContent={"center"} alignItems={"center"} direction={"row"}
                          wrap={"nowrap"} spacing={0.8}
                    >
                        <Grid item>
                            <Typography className={styles.timerClock}>{timeLeftToString(timeLeft)}</Typography>
                        </Grid>
                        {userIsBoardAdmin &&
                        <Grid item>
                            <SmallButtonComponent id={`timer-button-delete`}
                                                  icon={<Delete fontSize={"small"}/>}
                                                  onClick={onTimerDeleteCallback}
                                                  disabled={false}
                                                  variant={"text"}
                                                  size={"1rem"}
                                                  margin={"0.4rem"}
                            /></Grid>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>

    );
}

export default SessionTimerComponent
import SockJS from "sockjs-client"

const CLOSE_STATUS_NORMAL = 1000;

export default class WebSocketService {

    private readonly baseURL: string = process.env.REACT_APP_BASE_URL!!
    private websocket?: WebSocket
    private readonly listener: WebsocketListener

    constructor(listener: WebsocketListener) {
        this.listener = listener;
    }

    async connectWebsocket(token: string): Promise<void> {
        this.websocket = new SockJS(`${this.baseURL}socket`, null);
        this.websocket.onopen = () => {
            if (this.websocket === undefined)
                return Promise.reject();
            this.websocket.send(token);
            return Promise.resolve();
        }
        this.websocket.onerror = (err) => {
            console.error(`Error on websocket. Closing... Error: ${err}`);
        };
        this.websocket.onclose = (event) => {
            console.warn(`Websocket closed. Reason: ${event.code}`);
            if (event.code !== CLOSE_STATUS_NORMAL) {
                console.warn("Reconnecting websocket...");
                this.connectWebsocket(token);
            }
        };
        this.websocket.onmessage = (event: MessageEvent) => {
            const message = event.data;
            this.receiveWebsocketMessage(message);
        };
    }

    private receiveWebsocketMessage(message: string) {
        if (message !== "PING")
            this.listener(message);
    }

}

export type WebsocketListener = (message: string) => void
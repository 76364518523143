import {Button, Grid, IconButton, TextField} from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AddIcon from "@mui/icons-material/Add"

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import CreateColumnComponent, {colorOptions} from "../components/Columns/CreateColumnComponent";
import {ColumnDescription} from "../model/request/CreateBoardRequest";
import {getCurrentUser, User, withLogin, WithLoginProps} from "@2gether/frontend-library";
import {BoardClient} from "../api/BoardClient";
import {CookieManager} from "../controller/CookieManager";
import {ArrowBack} from "@mui/icons-material";
import {UserAccessEnum, UserContext} from "../contexts/UserContext";
import {FormattedMessage, IntlProvider} from "react-intl";
import enLocale from "../locales/en.json";
import deLocale from "../locales/de.json";
import {LocaleContext} from "../contexts/LocaleContext";


const useStyles = makeStyles(() =>
    createStyles({
        pageHeader: {
            display: "flex",
            alignItems: "center"
        },
        columnsHeading: {
            margin: "4px 0px"
        },
        parentDiv: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        contentDiv: {
            width: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        columnHeaderDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "8px"
        },
        columnNameGrid: {
            width: "100%",
            margin: "5px 0px"
        },
        columnNameGridItem: {
            padding: "0px",
            width: "100%"
        },
        retroStartDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
        },
        addColumnButton: {
            padding: "4px"
        }
    })
);


const CreateBoardPage: React.FC = () => {
    const styles = useStyles()
    const [retroName, setRetroName] = useState("")
    const [columnDescriptions, setColumnDescriptions] = useState<ColumnDescription[]>([{
        name: "",
        isAuthorAnonymous: false,
        color: colorOptions[0]
    }])
    const [takenColors, setTakenColors] = useState<string[]>([colorOptions[0]])
    const {user, updateUser} = useContext(UserContext)

    const locale = useContext(LocaleContext).locale === 'en' ? 'en' : 'de';
    const messages = {
        en: enLocale,
        de: deLocale
    }

    useEffect(updateUser, [updateUser])

    useEffect(() => {
        if (user.access === UserAccessEnum.NOT_AUTHORIZED) window.location.pathname = `/not-authorized`
    }, [user])

    useEffect(() => {
        const colors = columnDescriptions.map(column => column.color)
        setTakenColors(colors)
    }, [columnDescriptions, setTakenColors])

    const renderBackButton = () => {
        return (
            <IconButton
                onClick={() => {
                    window.location.pathname = `/`
                }}
                color={"primary"}
                style={{marginRight: "1rem"}}
                size="large">
                <ArrowBack/>
            </IconButton>
        );
    }

    const canCreateRetro = useMemo(() => {
            if (retroName.length === 0)
                return false
            return columnDescriptions.find((col) => col.name !== "") !== undefined
        }, [retroName, columnDescriptions]
    )

    const createBoard = (name: string, columns: ColumnDescription[]) => {
        getCurrentUser().then((currentUser) => {
            BoardClient.getSingleton().createBoard({
                name: name,
                columns: columns,
                adminName: currentUser.givenName + " " + currentUser.familyName,
                adminId: currentUser.username
            }).then((pair) => {
                CookieManager.setJWSToken(pair.left.id, currentUser.givenName + " " + currentUser.familyName, pair.right, `/retro/${pair.left.id}`)
                window.location.pathname = `retro/${pair.left.id}`
            })
        })
    }

    const removeColumn = useCallback((index: number) => {
        if (index > -1) {
            const newDescriptions = [...columnDescriptions].filter((column, i) => i !== index);
            setColumnDescriptions(newDescriptions)
            setTakenColors(newDescriptions.map(column => column.color))
        }
    }, [columnDescriptions, setColumnDescriptions])

    const filterEmptyColumns = (columns: ColumnDescription[]): ColumnDescription[] => {
        return columns.map((col) => Object.assign({}, col, {name: col.name.trim()}))
            .filter((col) => col.name !== "" && col.name !== '\r')
    }
    const countEmptyColumns = (columns: ColumnDescription[]): number => {
        let count = 0
        columns.forEach(col => {
            if (col.name === "") {
                count++
            }
        })
        return count
    }

    const addEmptyColumn = useCallback(() => {
        const color = colorOptions.filter((col) => !takenColors.includes(col))[4];
        setTakenColors([...takenColors, color])
        setColumnDescriptions(
            [
                ...columnDescriptions,
                {
                    name: "",
                    isAuthorAnonymous: false,
                    color: color
                }
            ]
        )
        // eslint-disable-next-line
    }, [columnDescriptions, setColumnDescriptions, setTakenColors])

    const updateColumns = useCallback(() => {
        const emptyColumnsCount = countEmptyColumns(columnDescriptions)
        let columns = [...columnDescriptions]
        if (emptyColumnsCount === 0) {
            if (columns.length < 10) {
                addEmptyColumn()
            }
        }
    }, [addEmptyColumn, columnDescriptions])

    const updateColumnState = useCallback((index: number, columnName: string, isAuthorAnonymous: boolean, color: string) => {
        let newColumns = [...columnDescriptions]
        newColumns[index].name = columnName.trimStart()
        newColumns[index].isAuthorAnonymous = isAuthorAnonymous
        newColumns[index].color = color
        setColumnDescriptions(newColumns)
        setTakenColors(newColumns.map(column => column.color))
    }, [columnDescriptions, setColumnDescriptions, setTakenColors])
    return (
        <IntlProvider locale={locale} messages={messages[locale]} key={'createboard'}>
            <div className={styles.parentDiv}>
                <div className={styles.contentDiv}>
                    <div className={styles.pageHeader}>
                        {renderBackButton()}
                        <h2><FormattedMessage id={"create-board-header"}/></h2>
                    </div>
                    <TextField
                        id={"create_board_page-text_field-retro_name"}
                        label={"Retro-Name"}
                        type={"text"}
                        value={retroName}
                        onChange={(event) => {
                            setRetroName(event.target.value)
                        }}
                        required={true}
                        variant={"outlined"}
                        fullWidth={true}
                    />
                    <div className={styles.columnHeaderDiv}>
                        <h3 className={styles.columnsHeading}><FormattedMessage id={"create-board-retro-column"}/></h3>
                        <IconButton
                            id={"create_board_page-icon_button-add_column"}
                            className={styles.addColumnButton}
                            aria-label="Spalte hinzufügen"
                            disabled={columnDescriptions.length >= 10}
                            onClick={addEmptyColumn}
                            color={"primary"}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <Grid container spacing={1} className={styles.columnNameGrid}>
                        {
                            columnDescriptions
                                .map((col, index) => {
                                    return (
                                        <Grid item className={styles.columnNameGridItem}
                                              style={{paddingLeft: 0, paddingRight: 0}}>
                                            <CreateColumnComponent
                                                key={index}
                                                id={`create_column_component-${index}`}
                                                columnName={col.name}
                                                columnIndex={index}
                                                color={col.color}
                                                isAnonymousToggleActive={col.isAuthorAnonymous}
                                                takenColors={takenColors}
                                                onChange={updateColumnState}
                                                updateColumns={updateColumns}
                                                columnAmount={columnDescriptions.length}
                                                removeColumn={() => removeColumn(index)}
                                            />
                                        </Grid>
                                    )
                                })
                        }
                    </Grid>
                    <div className={styles.retroStartDiv}>
                        <Button
                            id={"create_board_page-button-create_retro"}
                            disabled={!canCreateRetro}
                            onClick={() => createBoard(retroName, filterEmptyColumns(columnDescriptions))}
                            color={"primary"}
                            variant={"contained"}
                        ><FormattedMessage id={"create-board-start-button"}/>
                        </Button>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
}


const CreateBoardPageWithLogin: React.FC<WithLoginProps> = (props) => {
    const {user: retroUser} = useContext(UserContext)
    const {signOut} = props;
    const emptyUser: User = {
        username: "",
        email: "",
        givenName: "",
        familyName: "",
        mattermostUsername: "",
        role: null,
        groups: [],
        isAdmin: false,
        isAuthorized: false
    };
    const [user, setUser] = useState<User>(emptyUser);

    useMemo(() => {
        getCurrentUser()
            .then(currentUser => setUser(currentUser))
            .catch(() => signOut());
    }, [setUser, signOut])

    useEffect(() => {
        if (retroUser.access === UserAccessEnum.NOT_AUTHORIZED) {
            window.location.pathname = `/not-authorized`
        }
    }, [user, retroUser])

    return (retroUser.access === UserAccessEnum.NOT_AUTHORIZED) ? <></>
        : <CreateBoardPage/>
}

const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

export default withLogin({
    adminsOnly: false,
    requireAcl: true,
    cognitoUrl: "https://" + process.env.REACT_APP_OAUTH_DOMAIN!,
    clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    cookieStorage,
})(CreateBoardPageWithLogin)
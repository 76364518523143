import {Button, Grid} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import React from "react";
import {ArrowBack} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";

export const ErrorPage = () => {
    return (
        <Grid id={"error_on_page_failed"}
              container
              direction={"column"}
              spacing={2}>
            <Grid item>
                <h2><FormattedMessage id={'error-page-not-found'}/></h2>
            </Grid>
            <Grid item>
                <SentimentVeryDissatisfiedIcon/>
            </Grid>
            <Grid item>
                <Button onClick={() => {
                    window.location.pathname = "/"
                }}
                        color={"primary"}
                        startIcon={<ArrowBack/>}>
                    <FormattedMessage id={'error-page-back'}/>
                </Button>
            </Grid>
        </Grid>
    )
}
import React, {useContext} from 'react'
import {Theme, Typography} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {FormattedMessage, IntlProvider} from "react-intl";
import {LocaleContext} from "../../contexts/LocaleContext";
import enLocale from "../../locales/en.json";
import deLocale from "../../locales/de.json";

interface IRemainingVotesComponent {
    remainingVotes: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        remainingVotesLabel: {
            [theme.breakpoints.down('lg')]: {textAlign: 'center'},
            [theme.breakpoints.up('lg')]: {textAlign: 'left'}
        }
    }),
);

export const RemainingVotesComponent: React.FC<IRemainingVotesComponent> = (
    {
        remainingVotes
    }) => {

    const locale = useContext(LocaleContext).locale == 'en' ? 'en' : 'de';
    const messages = {
        en: enLocale,
        de: deLocale
    }

    const styles = useStyles();

    return (
        <React.Fragment>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <Typography variant={"body2"} className={styles.remainingVotesLabel} noWrap>
                    <FormattedMessage id={"remaining-votes-votes"}/>: {remainingVotes}
                </Typography>
            </IntlProvider>
        </React.Fragment>
    )
}

import {TextField} from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, {useState} from 'react'
import SmallButtonComponent from "../SmallButtonComponent";
import {Check, Close} from "@mui/icons-material";
import {useIntl} from "react-intl";

interface Props {
    initialText?: string,

    onAccept(cardText: string): void,

    onCancel(): void,

    columnId: number
}

const useStyles = makeStyles((theme) =>

    createStyles({
        editCardContainer: {
            width: "100%",
            color: theme.palette.text.secondary,
        },
        buttonDiv: {
            display: "flex",
            justifyContent: "flex-end"
        },
        divider: {
            marginBottom: "5px"
        },
        authorName: {
            whiteSpace: "pre-wrap",
            width: "100%",
            textAlign: "left"
        }
    })
);

const CardEditorComponent: React.FC<Props> = (props) => {
    const {initialText, onAccept, onCancel, columnId} = props;

    const styles = useStyles(props)
    const [cardText, setCardText] = useState<string>(initialText ?? "")
    const [saveButtonWasClicked, setSaveButtonWasClicked] = useState<boolean>(false)
    const cardTextFieldId = `create_card_component-${columnId}-text_field-new_card_text`

    const intl = useIntl();

    function saveEdit(cardText: string) {
        setSaveButtonWasClicked(true)
        onAccept(cardText)
    }

    return (
        <div className={styles.editCardContainer}>
            <TextField
                id={cardTextFieldId}
                label={intl.formatMessage({id: "card-editor-card-text"})}
                variant="standard"
                value={cardText}
                onChange={(event) => {
                    setCardText(event.target.value)
                }}
                
                fullWidth
                autoFocus
                multiline>
            </TextField>
            <div className={styles.buttonDiv}>
                <SmallButtonComponent
                    id={`create_card_component-${columnId}-button-create_card`}
                    icon={<Check style={{fill: "rgba(255, 255, 255, 0.89)"}}/>}
                    onClick={() => {
                        saveEdit(cardText)
                    }}
                    backgroundColor={"green"}
                    hoverBackgroundColor={"lightgreen"}
                    disabled={cardText === "" || saveButtonWasClicked}
                    margin={"2px"}

                />
                <SmallButtonComponent
                    id={`create_card_component-${columnId}-button-cancel`}
                    icon={<Close style={{fill: "rgba(255, 255, 255, 0.89)"}}/>}
                    onClick={onCancel}
                    backgroundColor={"red"}
                    hoverBackgroundColor={"pink"}
                    disabled={false}
                    margin={"2px"}
                />
            </div>
        </div>
    )
}

export default CardEditorComponent



import React from 'react'
import {
    Draggable,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided
} from "react-beautiful-dnd";

import {MergedCard, SimpleCard} from "../../model/Card";
import {Paper} from "@mui/material";
import SimpleCardComponent from "./SimpleCardComponent";
import {Board, HighlightModes} from "../../model/Board";
import {Column} from "../../model/Column";

interface Props {
    card: MergedCard
    columnId: number
    id: string,
    cardIndex: number
    votesVisible: boolean,
    votingVisible: boolean,
    board: Board
    column: Column
    currentDraggableId: string | null
    editActive: boolean
    setEditActive: (value: boolean) => void
    sorted: boolean
    styles: any
    value: boolean
    isEditable: number
    setIsEditable: (value: number) => void
}

// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
// this somewhat breaks splitting on mobile see:
// https://github.com/atlassian/react-beautiful-dnd/issues/1869
// you can still split by selecting the subcard and releasing and then perform the drag
const getRenderSubCard = (props: Props, subcards: SimpleCard[], styles: any, isBlurred: boolean) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
    <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
    >
        <Paper className={styles.subCard} elevation={2}>
            {subcards[rubric.source.index].hidden ? (
                <div className={styles.hidden}/>
            ) : (
                <SimpleCardComponent
                    card={subcards[rubric.source.index]}
                    board={props.board}
                    provided={null}
                    styles={styles}
                    isSubcard={true}
                    isBlurred={isBlurred}
                    columnId={props.columnId}
                    editActive={props.editActive}
                    setEditActive={props.setEditActive}
                    setHighlightedCardId={() => {
                    }}
                    isEditable={props.isEditable}
                    setIsEditable={props.setIsEditable}
                />
            )}
        </Paper>
    </div>
);

export const SubCardsComponent: React.FC<Props> = (props) => {
    const {card, columnId, styles, editActive, board, value} = props;
    if (card.childCards === undefined || card.childCards === null)
        return null;
    const isBlurred = (board.highlightedCardId !== -1) && (card.id !== board.highlightedCardId)
        && (board.highlightMode === HighlightModes.FadeOut)
    const renderSubCard = getRenderSubCard(props, card.childCards, styles, isBlurred);
    return (
        <Droppable
            droppableId={"subcards-" + card.id}
            isDropDisabled={true}
            renderClone={renderSubCard}
            type={"CARDS"}
        >
            {(provided: DroppableProvided) =>
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {card.childCards!!.map((card, i) => (
                        <Draggable draggableId={"subcard-" + columnId + "-" + card.id}
                                   index={i}
                                   key={card.id}
                                   isDragDisabled={editActive || !board.showAllCards}>
                            {renderSubCard}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            }
        </Droppable>
    )
}
import {Button, Snackbar, TextField} from "@mui/material"
import React, {Dispatch, SetStateAction, useState} from 'react'
import {FormattedMessage, useIntl} from "react-intl";

interface Props {
    signIn: (email: string, password: string) => void,
    styles: any,
    error: boolean,
    setError: Dispatch<SetStateAction<boolean>>
}

const UserLoginComponent: React.FC<Props> = (props) => {
    const [eMail, setEMail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const {signIn, styles, error, setError} = props

    const intl = useIntl();

    return (
        <div className={styles.parentDiv}>
            <div className={styles.contentDiv}>
                <h2 className={styles.retroHeading}><FormattedMessage id={'login-page-login-with-2gether'}/></h2>
                <TextField
                    id="user_login_component-text_area-email"
                    className={styles.textField}
                    label="E-Mail"
                    value={eMail}
                    variant="outlined"
                    autoComplete="email"
                    onChange={(event) => {
                        setEMail(event.target.value)
                    }}
                    fullWidth>
                </TextField>
                <TextField
                    id="user_login_component-text_area-password"
                    className={styles.textField}
                    label={intl.formatMessage({id: "login-page-password"})}
                    value={password}
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(event) => {
                        setPassword(event.target.value)
                    }}
                    fullWidth>
                </TextField>
                <div className={styles.retroStartDiv}>
                    <Button
                        id="user_login_component-button-log_into_account"
                        className={styles.button}
                        onClick={() => signIn(eMail, password)}
                        color={"primary"}
                        disabled={eMail === "" || password === ""}
                        variant={"contained"}>
                        <FormattedMessage id={'login-page-login-btn'}/>
                    </Button>
                    <Snackbar className={styles.snack}
                              message={intl.formatMessage({id: "login-page-error"})}
                              open={error}
                              autoHideDuration={6000}
                              onClose={() => setError(false)}
                              ContentProps={{
                                  classes: {
                                      root: styles.snack
                                  }
                              }}
                    />
                </div>
            </div>
        </div>
    )
}
export default UserLoginComponent




import {Alert, AlertTitle, Button, Grid, Snackbar} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


import React, {useContext, useEffect, useMemo, useState} from 'react'
import {getCurrentUser, User, withLogin, WithLoginProps} from "@2gether/frontend-library";
import BoardTileListComponent from "../components/BoardOverview/BoardTileListComponent";
import {BoardClient} from "../api/BoardClient";
import {BoardInfo} from "../model/BoardInfo";
import {CookieManager} from "../controller/CookieManager";
import {UserAccessEnum, UserContext} from "../contexts/UserContext";
import {FormattedMessage, IntlProvider} from "react-intl"
import enLocale from "../locales/en.json"
import deLocale from "../locales/de.json"
import {LocaleContext} from "../contexts/LocaleContext";

interface Props {
    user: User
}

const useStyles = makeStyles(() =>
    createStyles({
        pageContent: {
            width: "80%",
            margin: "0 auto"
        },
        pageHeader: {
            width: "100%",
            margin: "1.5rem 0"
        },
        pageHeading: {
            textAlign: "left",
            margin: "0"
        },
        createButton: {
            margin: "auto 0",
            width: "100%"
        },
        boardListsGrid: {
            display: "flex",
            flexDirection: "column"
        },
        boardListsItem: {
            marginBottom: "1rem"
        },
    })
);


export const BoardOverviewPage: React.FC<Props> = (props) => {
    const styles = useStyles()
    const {user} = props

    const locale = useContext(LocaleContext).locale === "de" ? "de" : "en";
    const messages = {
        "en": enLocale,
        "de": deLocale
    }

    const [boardsOwnedByUser, setBoardsOwnedByUser] = useState<BoardInfo[]>([])
    const [boardsVisitedByUser, setBoardsVisitedByUser] = useState<BoardInfo[]>([])
    const [boardsOwnedByUserHasLoaded, setBoardsOwnedByUserHasLoaded] = useState(false)
    const [boardsVisitedByUserHasLoaded, setBoardsVisitedByUserHasLoaded] = useState(false)
    const [displayBoardNotFoundError, setDisplayBoardNotFoundError] = useState(false)
    const [displayOwnBoardListLoadingError, setDisplayOwnBoardListLoadingError] = useState(false)
    const [displayVisitedBoardListLoadingError, setDisplayVisitedBoardListLoadingError] = useState(false)
    const {user: retroUser, updateUser} = useContext(UserContext)

    useEffect(updateUser, [updateUser])

    useEffect(() => {
        if (retroUser.access === UserAccessEnum.NOT_AUTHORIZED) window.location.pathname = `/not-authorized`
        // eslint-disable-next-line
    }, [user])


    function loadBoards() {
        setBoardsOwnedByUserHasLoaded(false)
        setBoardsVisitedByUserHasLoaded(false)
        setBoardsOwnedByUser([])
        setBoardsVisitedByUser([])
        getCurrentUser()
            .then(user => {
                BoardClient.getSingleton().getBoardsOwnedByUser(user.username)
                    .then(boardInfos => {
                        setBoardsOwnedByUser(boardInfos)
                    })
                    .catch(() => setDisplayOwnBoardListLoadingError(true))
                    .finally(() => setBoardsOwnedByUserHasLoaded(true))
            })
        getCurrentUser()
            .then(user => {
                BoardClient.getSingleton().getBoardsVisitedByUser(user.username)
                    .then(boardInfos => {
                        setBoardsVisitedByUser(boardInfos)
                    })
                    .catch(() => setDisplayVisitedBoardListLoadingError(true))
                    .finally(() => setBoardsVisitedByUserHasLoaded(true))
            })
    }

    useEffect(loadBoards, [])

    function navigateToBoard(boardInfo: BoardInfo) {
        const boardId = boardInfo.boardId
        BoardClient.getSingleton().getBoardWithId(boardId, CookieManager.getJWSToken(boardId, ""))
            .then(() => window.location.pathname = `retro/${boardId}`)
            .catch(() => {
                setDisplayBoardNotFoundError(true)
                loadBoards()
            })
    }

    function renderErrors(): JSX.Element {
        return <Snackbar open={true}>

            <Alert severity={"error"}
                   variant={"filled"}
                   onClose={() => {

                       const reload = displayBoardNotFoundError;
                       setDisplayBoardNotFoundError(false)
                       setDisplayOwnBoardListLoadingError(false)
                       setDisplayVisitedBoardListLoadingError(false)

                       if (reload)
                           loadBoards()
                   }}>
                <AlertTitle><FormattedMessage id={"board-overview-error"}/></AlertTitle>
                {displayBoardNotFoundError && <p><FormattedMessage id={"board-overview-board-not-found"}/></p>}
                {displayOwnBoardListLoadingError &&
                    <p><FormattedMessage id={"board-overview-own-board-listing-error"}/></p>}
                {displayVisitedBoardListLoadingError &&
                    <p><FormattedMessage id={"board-overview-display-visited-board-error"}/></p>}
            </Alert>
        </Snackbar>
    }

    function deleteRetro(boardInfo: BoardInfo): void {
        BoardClient.getSingleton()
            .createUserToken({
                name: user.givenName.toString() + " " + user.familyName.toString(),
                id: user.username,
                boardId: boardInfo.boardId
            })
            .then(token =>
                BoardClient.getSingleton()
                    .deleteBoard(boardInfo.boardId, token)
                    .then(loadBoards))
    }

    function renderBoardTileList(title: string, isOwner: boolean, boardInfos: BoardInfo[], onTileClick: (boardInfo: BoardInfo) => void): JSX.Element {
        const boardsAreLoading = (!boardsOwnedByUserHasLoaded && !boardsVisitedByUserHasLoaded)
        return <BoardTileListComponent listTitle={title} onDeleteClick={isOwner ? deleteRetro : undefined}
                                       boardInfos={boardInfos}
                                       onTileClick={onTileClick} boardsAreLoading={boardsAreLoading}/>
    }

    return (
        <div className={styles.pageContent}>
            <IntlProvider locale={locale} messages={messages[locale]} defaultLocale={locale}>
                <Grid container className={styles.pageHeader} alignItems={"center"}>
                    <Grid item xs={12} sm={7} md={9}>
                        <h1 className={styles.pageHeading}>Retro2Gether</h1>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3}>
                        <Button
                            className={styles.createButton}
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => {
                                window.location.pathname = `create`
                            }}
                        >
                            <FormattedMessage id={'board-overview-create-retro'}/>
                        </Button>
                    </Grid>
                </Grid>
                <div className={styles.boardListsGrid}>
                    <div className={styles.boardListsItem}>
                        {
                            renderBoardTileList('board-overview-my-retros', true, boardsOwnedByUser, navigateToBoard)
                        }
                    </div>
                    <div className={styles.boardListsItem}>
                        {
                            renderBoardTileList("board-overview-other-retros", false, boardsVisitedByUser, navigateToBoard)
                        }
                    </div>
                </div>
                {(displayBoardNotFoundError
                        || displayOwnBoardListLoadingError
                        || displayVisitedBoardListLoadingError)
                    && renderErrors()}
            </IntlProvider>
        </div>

    )
}

const BoardOverviewPageWithLogin: React.FC<WithLoginProps> = (props) => {
    const {user: retroUser} = useContext(UserContext)
    const {signOut} = props;
    const emptyUser: User = {
        username: "",
        email: "",
        givenName: "",
        familyName: "",
        mattermostUsername: "",
        role: null,
        groups: [],
        isAdmin: false,
        isAuthorized: false
    };
    const [user, setUser] = useState<User>(emptyUser);

    useMemo(() => {
        getCurrentUser()
            .then(currentUser => setUser(currentUser))
            .catch(() => signOut());
    }, [setUser, signOut])

    useEffect(() => {
        if (retroUser.access === UserAccessEnum.NOT_AUTHORIZED) {
            window.location.pathname = `/not-authorized`
        }
    }, [user, retroUser])

    return (retroUser.access === UserAccessEnum.NOT_AUTHORIZED) ? <></>
        : <BoardOverviewPage user={user}/>
}

const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

export default withLogin({
    adminsOnly: false,
    requireAcl: true,
    cognitoUrl: "https://" + process.env.REACT_APP_OAUTH_DOMAIN!,
    clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    cookieStorage,
})(BoardOverviewPageWithLogin)




import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.css';
import "./AmplifyConfig"
import BoardOverviewPage from "./pages/BoardOverviewPage";
import maternaTheme from "./styles/materna_theme";
import {StyledEngineProvider, Theme, ThemeProvider} from "@mui/material";
import BoardLoadingPage from "./pages/BoardLoadingPage";
import {ApolloClient, ApolloLink, ApolloProvider, InMemoryCache} from "@apollo/client";
import {AUTH_TYPE, createAuthLink} from "aws-appsync-auth-link";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";
import {AuthClass} from "@aws-amplify/auth/lib/Auth";
import {NotFoundPage} from "./pages/NotFoundPage";
import CreateBoardPage from "./pages/CreateBoardPage";
import UserProvider from "./contexts/UserContext";
import {NotAuthorizedPage} from "./pages/NotAuthorizedPage";
import LocaleProvider from "./contexts/LocaleContext";


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}


const authConfig = {
    'region': process.env.REACT_APP_AWS_REGION,
    'identityPoolId': process.env.REACT_APP_AWS_COGNITO_IDENTITIY_POOL_ID
}

const config = {
    url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    region: process.env.REACT_APP_AWS_REGION,
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => new AuthClass(authConfig).currentCredentials()
    }
};

const link = ApolloLink.from([
    // @ts-ignore
    createAuthLink(config),
    // @ts-ignore
    createSubscriptionHandshakeLink(config)
]);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({addTypename: false})
});

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={maternaTheme}>
                <ApolloProvider client={client}>
                    <UserProvider>
                        <LocaleProvider>
                            <div className="App">
                                <BrowserRouter>
                                    <Switch>
                                        <Route path={"/"} exact>
                                            <BoardOverviewPage />                   
                                        </Route>
                                        <Route path={"/retro/:boardId"}>
                                            <BoardLoadingPage/>
                                        </Route>
                                        <Route path={"/create"}>
                                            <CreateBoardPage/>
                                        </Route>
                                        <Route path={"/not-authorized"}>
                                            <NotAuthorizedPage/>
                                        </Route>
                                        <Route component={NotFoundPage}/>
                                    </Switch>
                                </BrowserRouter>
                            </div>
                        </LocaleProvider>
                    </UserProvider>
                </ApolloProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;

import {BoardTimer, BoardTimerDto} from "../model/BoardTimer";
import {Board, BoardDto, HighlightModes} from "../model/Board";
import {Column, ColumnDto} from "../model/Column";
import {ACard, ACardDTO} from "../model/Card";
import {UserIsReady, UserIsReadyDto} from "../model/UserIsReady";

export function mapBoardTimerDtoToBoardTimer(timerDto: BoardTimerDto): BoardTimer {
    const millisecondsRemaining = timerDto.secondsRemaining * 1000
    const timerEndpointInMilliseconds = Date.now() + millisecondsRemaining
    return {
        active: timerDto.active,
        name: timerDto.name,
        timerEndpoint: new Date(timerEndpointInMilliseconds)
    }
}

export function mapBoardDtoToBoard(boardDto: BoardDto): Board {
    return {
        id: boardDto.id,
        name: boardDto.name,
        showAllCards: boardDto.showAllCards,
        columns: boardDto.columns.map(mapColumnDtoToColumn),
        boardVersion: boardDto.boardVersion,
        showVotes: boardDto.showVotes,
        downVoting: boardDto.downVoteAllowed,
        voting: boardDto.voting,
        multiVoteAllowed: boardDto.multiVoteAllowed,
        votesLimited: boardDto.votesLimited,
        maxVotes: boardDto.maxVotes,
        playMusic: boardDto.playMusic,
        highlightedCardId: boardDto.highlightedCardId,
        readinessRequested: boardDto.readinessRequested,
        readyFlags: boardDto.readyFlags.map(mapUserIsReadyDtoToUserIsReady),
        userIsDoneWithCards: boardDto.userIsDoneWithCards,
        userIsDoneWithVotes: boardDto.userIsDoneWithVotes,
        highlightMode: HighlightModes[boardDto.highlightMode as keyof typeof HighlightModes],
        timer: mapBoardTimerDtoToBoardTimer(boardDto.timer),
        showCardAuthors: boardDto.showingAuthorNames
    };
}

export function mapColumnDtoToColumn(columnDto: ColumnDto): Column {
    return {
        id: columnDto.id,
        position: columnDto.position,
        name: columnDto.name,
        cards: columnDto.cards.map(mapCardDtoToCard),
        isAuthorAnonymous: columnDto.isAuthorAnonymous,
        color: columnDto.color,
    };
}

export function mapCardDtoToCard(cardDto: ACardDTO): ACard {
    const common = {
        votesForCardFromUser: cardDto.votesForCardFromUser,
        downVotesForCardFromUser: cardDto.downVotesForCardFromUser,
        votesForCard: cardDto.votesForCard,
        downVotesForCard: cardDto.downVotesForCard,
        id: cardDto.id,
        creationTime: cardDto.creationTime,
        uniqueVotesForCard: cardDto.uniqueVotesForCard,
        uniqueDownVotesForCard: cardDto.uniqueDownVotesForCard,
    };

    if (cardDto.type === 'SimpleCard') {
        return {
            ...common,
            text: cardDto.text,
            authorName: cardDto.authorName,
            anonymous: cardDto.anonymous,
            hidden: cardDto.hidden,
            type: 'SimpleCard',
            owned: cardDto.owned,
        };
    } else {
        return {
            ...common,
            type: 'MergedCard',
            childCards: cardDto.childCards,
        };
    }
}

export function mapUserIsReadyDtoToUserIsReady(userIsReadyDto: UserIsReadyDto): UserIsReady {
    return {
        userName: userIsReadyDto.userName,
        votingReady: userIsReadyDto.votingReady,
        cardsReady: userIsReadyDto.cardsReady
    }
}

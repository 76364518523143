import React, {useRef} from "react";
import ColumnInfo from "../../model/ColumnInfo";
import { Tooltip, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useIsTextTruncated} from "../../hooks/UseIsTextTruncated";

interface Props {
    columnInfo: ColumnInfo
}
const useStyles = makeStyles(() =>
    createStyles({
        columnName: {
            backgroundColor: "lightgrey",
            borderRadius: "5px",
            marginBottom: "5px",
            padding: "0.5rem"
        }
    })
)


export default function ColumnNameComponent(props: Props) {

    const styles = useStyles()
    const { columnInfo } = props;

    const columnNameRef = useRef<HTMLElement>();
    const isColumnNameTruncated = useIsTextTruncated(columnNameRef, columnInfo.name)

    return <Tooltip title={columnInfo.name} disableHoverListener={!isColumnNameTruncated} placement={'top-start'}>
        <Typography
            noWrap={true}
            className={styles.columnName}
            style={{backgroundColor: columnInfo.color}}
            component={"p"}
            ref={(instance: HTMLElement | null) => columnNameRef.current = instance ?? undefined}
        >
            {columnInfo.name}
        </Typography>
    </Tooltip>
}
import {createTheme} from '@mui/material/styles';

const maternaTheme = createTheme({
        palette: {
            primary: {main: '#c30a17'},
            secondary: {main: '#3a3E41'},
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 750,
                lg: 1830,
                xl: 2500,
            }
        },
    })
;

export default maternaTheme;
import {Board, BoardCreationDto, BoardDto, HighlightModes} from '../model/Board';
import {CreateBoardRequest} from '../model/request/CreateBoardRequest';
import {ACard, ACardDTO} from '../model/Card';
import {CreateCardRequest} from '../model/request/CreateCardRequest';
import {CreateUserRequest} from '../model/request/CreateUserRequest';
import {Pair} from '../model/datastructures';

import {MergeCardRequest} from '../model/request/MergeCardRequest';
import {MoveCardRequest} from '../model/request/MoveCardRequest';
import {SplitCardRequest} from '../model/request/SplitCardRequest';
import {ServerResponse} from '../model/ServerResponse';
import {EditCardRequest} from '../model/request/EditCardRequest';
import {MoveColumnRequest} from '../model/request/MoveColumnRequest';
import {CreateColumnRequest} from '../model/request/CreateColumnRequest';
import {EditColumnRequest} from '../model/request/EditColumnRequest';
import {DeleteColumnRequest} from '../model/request/DeleteColumnRequest';
import {BoardInfo, BoardInfoDto} from '../model/BoardInfo';
import {withAuthenticationHeader, withJsonContentHeader, withUserTokenHeader} from "./requestHelpers";
import {mapBoardDtoToBoard, mapCardDtoToCard} from "./mappers";
import {apiConfig} from "./ApiConfiguration";
import {BOARD_URL, JWS_USER_URL} from "./urls";
import {EditReadyUserRequest} from "../model/request/EditReadyUserRequest";


export class BoardClient {

    private readonly apiConfig = apiConfig({
        boardUrl: BOARD_URL,
        jwsServiceUrl: JWS_USER_URL
    });

    private _mutationFailureCallback: () => any = () => null;

    private static readonly _instance: BoardClient = new BoardClient();

    constructor() {
    }

    set mutationFailureCallback(value: () => any) {
        this._mutationFailureCallback = value;
    }

    public static getSingleton(): BoardClient {
        return BoardClient._instance;
    }

    public async createBoard(
        request: CreateBoardRequest
    ): Promise<Pair<Board, string>> {
        const url = this.apiConfig.urls.boardUrl('create');
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(await withAuthenticationHeader()),
        });
        switch (response.status) {
            case 200:
                const boardDto: BoardCreationDto = await response.json();
                this.apiConfig.setLastKnownVersion(boardDto.board.boardVersion);
                return {
                    left: mapBoardDtoToBoard(boardDto.board),
                    right: boardDto.adminUserToken,
                };
            default:
                return Promise.reject(await response.text());
        }
    }

    public async createUserToken(request: CreateUserRequest): Promise<string> {
        const response = await fetch(this.apiConfig.urls.jwsServiceUrl(), {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(),
        });
        switch (response.status) {
            case 200:
                return await response.text();
            default:
                return Promise.reject(await response.text());
        }
    }

    public async addCard(
        request: CreateCardRequest,
        token: string
    ): Promise<ServerResponse<ACard>> {
        const url = this.apiConfig.urls.boardUrl(request.boardId + '/cards/add');
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        switch (response.status) {
            case 201:
                const serverResponse: ServerResponse<ACardDTO> = await response.json();
                this.apiConfig.setLastKnownVersion(serverResponse.boardVersion);
                return {
                    result: mapCardDtoToCard(serverResponse.result),
                    boardVersion: serverResponse.boardVersion,
                };
            default:
                this._mutationFailureCallback();
                return Promise.reject(await response.text());
        }
    }

    public async editCard(
        request: EditCardRequest,
        boardId: string,
        cardId: number,
        token: string
    ): Promise<ServerResponse<void>> {
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const url = this.apiConfig.urls.boardUrl(boardId + '/cards/edit/' + cardId);
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    public async deleteCard(
        board: Board,
        card: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/cards/delete/${card}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async toggleCardReveal(
        board: Board,
        token: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(board.id + '/setReveal');
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(token)),
            body: JSON.stringify({
                value: !board.showAllCards,
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async mergeCard(
        boardId: string,
        request: MergeCardRequest,
        token: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${boardId}/cards/merge`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    public async moveCard(
        boardId: string,
        request: MoveCardRequest,
        token: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${boardId}/cards/move`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    public async splitCard(
        boardId: string,
        request: SplitCardRequest,
        token: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${boardId}/cards/split`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    public async moveColumn(
        boardId: string,
        request: MoveColumnRequest,
        token: string
    ): Promise<void> {
        const url = this.apiConfig.urls.boardUrl(`${boardId}/columns/move`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    async createColumn(
        boardId: Board,
        request: CreateColumnRequest,
        token: string
    ) {
        const url = this.apiConfig.urls.boardUrl(`${boardId.id}/columns/add`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(request),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    async editColumn(
        board: Board,
        column: number,
        request: EditColumnRequest,
        token: string
    ) {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/columns/${column}/edit/`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(board),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    async deleteColumn(
        board: Board,
        column: number,
        request: DeleteColumnRequest,
        token: string
    ) {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/columns/${column}/delete`);
        request = Object.assign({}, request, {
            sourceBoardVersion: this.getSourceBoardVersion(board),
        });
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: withJsonContentHeader(withUserTokenHeader(token)),
        });
        return this.resolveMutationResponse(response)
    }

    public async voteForCard(
        board: Board,
        card: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/vote/add/${card}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async downVoteForCard(
        board: Board,
        card: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/vote/add_downvote/${card}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async removeVoteForCard(
        board: Board,
        card: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/vote/remove/${card}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async removeDownVoteForCard(
        board: Board,
        card: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/vote/remove_downvote/${card}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion: this.getSourceBoardVersion(board),
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async toggleVoting(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setVoting`);
        return await this.sendSettingsPostRequest(url, userToken, !board.voting, board);
    }

    public async resetDownVotes(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/vote/reset_downvotes`);
        return await this.sendResetDownVotesPostRequest(url, userToken, board);
    }

    public async toggleDownVoting(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setDownVotes`);

        return await this.sendSettingsPostRequest(url, userToken, !board.downVoting, board);
    }

    public async toggleVotesVisible(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setVotesVisible`);
        return await this.sendSettingsPostRequest(url, userToken, !board.showVotes, board);
    }

    public async toggleCardAuthorsVisible(
        board: Board,
        showAuthors: boolean,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setCardAuthorsVisible`);
        return await this.sendSettingsPostRequest(url, userToken, showAuthors, board);
    }

    public async toggleMultipleVotes(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setMultipleVotes`);
        return await this.sendSettingsPostRequest(url, userToken, !board.multiVoteAllowed, board)
    }

    public async toggleReadinessRequested(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/readinessRequested`);
        return await this.sendSettingsPostRequest(url, userToken, !board.readinessRequested, board)
    }

    public async editReadyUser(
        request: EditReadyUserRequest,
        userToken: string,
        board: Board
    ): Promise<ServerResponse<void>> {
        request = Object.assign({}, request, {
            sourceBoardVersion:
                this.getSourceBoardVersion(board)
        });
        const url = this.apiConfig.urls.boardUrl(board.id + '/readinessFlags/edit/');
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'X-Retro-JWS-token': userToken,
            },
        });
        return this.resolveMutationResponse(response)
    }

    public async toggleVotesLimited(
        board: Board,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setVotesLimited`);
        return await this.sendSettingsPostRequest(url, userToken, !board.votesLimited, board);
    }

    public async togglePlayMusic(
        board: Board,
        userToken: string,
        isActiveMusic?: boolean
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/playMusic`);
        if (isActiveMusic != undefined) {
            return await this.sendSettingsPostRequest(url, userToken, isActiveMusic, board);
        }
        return await this.sendSettingsPostRequest(url, userToken, !board.playMusic, board);
    }

    public async setMaxVotes(
        board: Board,
        value: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/maxVotes`);
        return await this.sendSettingsPostRequest(url, userToken, value, board)
    }

    public async changeHighlightMode(
        board: Board,
        mode: HighlightModes,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setHighlightMode`);
        return await this.sendSettingsPostRequest(url, userToken, mode as string, board);
    }

    private async sendResetDownVotesPostRequest(url: string, userToken: string, board: Board): Promise<ServerResponse<void>> {
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                sourceBoardVersion:
                    this.getSourceBoardVersion(board)
            }),
        });
        return await this.resolveMutationResponse(response)
    }

    private async sendSettingsPostRequest(
        url: string,
        userToken: string,
        value: string | number | boolean,
        board: Board
    ): Promise<ServerResponse<void>> {
        const response = await fetch(url, {
            method: 'POST',
            headers: withJsonContentHeader(withUserTokenHeader(userToken)),
            body: JSON.stringify({
                value: value,
                sourceBoardVersion:
                    this.getSourceBoardVersion(board)
            }),
        });
        return this.resolveMutationResponse(response)
    }

    public async setHighlightedCardId(
        board: Board,
        value: number,
        userToken: string
    ): Promise<ServerResponse<void>> {
        const url = this.apiConfig.urls.boardUrl(`${board.id}/setHighlightedCardId`);
        return await this.sendSettingsPostRequest(url, userToken, value, board);
    }

    public getSourceBoardVersion(withVersion: { sourceBoardVersionId: number } | { boardVersion: number }) {
        let version;
        if ("sourceBoardVersionId" in withVersion) {
            version = withVersion.sourceBoardVersionId;
        } else {
            version = withVersion.boardVersion;
        }
        return version < this.apiConfig.getLastKnownVersion()
            ? this.apiConfig.getLastKnownVersion()
            : version;
    }

    public async resolveMutationResponse(response: Response) {
        switch (response.status) {
            case 200:
                const serverResponse = await response.json();
                this.apiConfig.setLastKnownVersion(serverResponse.boardVersion);
                return Promise.resolve(serverResponse);
            default:
                this._mutationFailureCallback();
                return Promise.reject(await response.text());
        }
    }

    public async getBoardWithId(
        id: string,
        token: string | undefined
    ): Promise<Board> {
        const url = this.apiConfig.urls.boardUrl(id);
        let headers = withJsonContentHeader()
        if (token !== undefined) {
            headers = withUserTokenHeader(token, headers)
        }
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        switch (response.status) {
            case 200:
                const boardDto: BoardDto = await response.json();
                return mapBoardDtoToBoard(boardDto);
            default:
                return Promise.reject(await response.text());
        }
    }

    public async deleteBoard(boardId: string, userToken: string): Promise<BoardInfo[]> {
        const url = this.apiConfig.urls.boardUrl(`${boardId}/delete`);
        const response = await fetch(url, {
            method: 'POST',
            headers: await withAuthenticationHeader(withJsonContentHeader(withUserTokenHeader(userToken))),
        });
        switch (response.status) {
            case 200:
                const boardInfo: BoardInfoDto[] = [await response.json()];
                return boardInfo
                    .map(it => Object.assign({...it}, {creationTime: new Date(it.creationTime)}));
            default:
                return Promise.reject(await response.text());
        }
    }

    public async getBoardsOwnedByUser(userId: string): Promise<BoardInfo[]> {
        const url = this.apiConfig.urls.boardUrl(`info/owned-by-user/${userId}`);
        const response = await fetch(url, {
            method: 'GET',
            headers: await withAuthenticationHeader(withJsonContentHeader()),
        });
        switch (response.status) {
            case 200:
                const boardInfo: BoardInfoDto[] = await response.json();
                return boardInfo
                    .map(it => Object.assign({...it}, {creationTime: new Date(it.creationTime)}));
            default:
                return Promise.reject(await response.text());
        }
    }

    public async getBoardsVisitedByUser(userId: string): Promise<BoardInfo[]> {
        const url = this.apiConfig.urls.boardUrl(`info/user-was-user/${userId}`);
        const response = await fetch(url, {
            method: 'GET',
            headers: await withAuthenticationHeader(withJsonContentHeader()),
        });
        switch (response.status) {
            case 200:
                const boardInfo: BoardInfoDto[] = await response.json();
                return boardInfo
                    .map(it => Object.assign({...it}, {creationTime: new Date(it.creationTime)}));
            default:
                return Promise.reject(await response.text());
        }
    }
}

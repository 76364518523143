import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {Board, HighlightModes} from '../model/Board';
import ColumnLayoutComponent from '../components/Board/ColumnLayoutComponent';
import {CookieManager} from '../controller/CookieManager';
import SidePanel from '../components/Board/SidePanel';
import {BoardClient} from '../api/BoardClient';
import BoardHeaderComponent from '../components/Board/BoardHeaderComponent';
import {debounce} from '../utilities';
import ConfirmationDialog, {ConfirmationDialogProps} from "../components/ConfirmationDialog";
import WebSocketService from "../services/BoardSocketSubscriber";
import {BoardTimerClient} from "../api/BoardTimerClient";
import Sound from "react-sound";
import {Column} from "../model/Column";

const BOARD_NOT_FOUND_ERROR_MESSAGE_EN = "Board could not be found. It might have been deleted."
const BOARD_NOT_FOUND_ERROR_MESSAGE_DE = "Dieses Board konnte nicht gefunden werden. Möglicherweise wurde es gelöscht."
const ELEVATOR_MUSIC = process.env.REACT_APP_ELEVATOR_MUSIC;

interface Props {
    board: Board;
    columns: Column[],
    name: string;
    reloadBoard: () => void;
    addVote: (voterName: string, cardId: number, uniqueVotesForCard: number) => void;
    removeVote: (voterName: string, cardId: number, uniqueVotesForCard: number) => void;
    addDownVote: (voterName: string, cardId: number, uniqueVotesForCard: number) => void;
    removeDownVote: (voterName: string, cardId: number, uniqueVotesForCard: number) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        boardPageDiv: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100vh',
        },
        columnsDiv: {
            padding: '24px',
            overflow: 'auto',
            height: '100%',
        },
    })
);

export const errorMessageContext = React.createContext<string | null>(null);
export const setErrorMessageContext = React.createContext<(value: string | null) => void>(() => {
});
export const userTokenContext = createContext<string | undefined>(undefined)

export const confirmationDialogContext = createContext<ConfirmationDialogProps>({
    open: false,
    message: "",
    onAccept: () => {
    },
    onCancel: () => {
    }
})

export const handleConfirmationDialogPropsContext = createContext((props: ConfirmationDialogProps) => {
})

const boardUpdateMessage = "BOARD UPDATE";
export const BoardPage: React.FC<Props> = (props) => {
        const styles = useStyles();

        const {board, columns, name, reloadBoard, addVote, removeVote, addDownVote, removeDownVote} = props;
        const [sorted, setSorted] = useState(false);
        const [editActive, setEditActive] = useState(false);
        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const [votesUnlimited, setVotesUnlimited] = useState<boolean>(
            !board.votesLimited
        );
        const [showCardAuthors, setShowCardAuthors] = useState<boolean>(board.showCardAuthors);
        const [confirmationDialogProps, setConfirmationDialogProps] = useState({
            open: false,
            message: "",
            onAccept: () => {
            },
            onCancel: () => {
            }
        });
        const [shouldPlayMusic, setShouldPlayMusic] = useState<boolean>(true);
        const [isCardEditable, setIsCardEditable] = useState(-1);

        const showCardAuthorsHandler = () => {
            setShowCardAuthors(!showCardAuthors)

        }
        const handleConfirmationDialogPropsChange = useCallback((props: ConfirmationDialogProps) => {
            setConfirmationDialogProps(props)
        }, [setConfirmationDialogProps])

        const handleErrorMessage = (message: string | null) => {
            if (message === BOARD_NOT_FOUND_ERROR_MESSAGE_EN) {
                setErrorMessage(BOARD_NOT_FOUND_ERROR_MESSAGE_DE);
            } else if (message === null) {
                setErrorMessage(null);
            }
        }

        const userToken = CookieManager.getJWSToken(board.id, name)

        const maxVotes =
            board.maxVotes < 0 || !board.votesLimited ? undefined : board.maxVotes;

        const calculateUserVotes = useCallback(() => {
            let count = 0;
            for (let i = 0; i < board.columns.length; i++) {
                for (let j = 0; j < board.columns[i].cards.length; j++) {
                    count += board.columns[i].cards[j].votesForCardFromUser;
                }
            }
            return count;
        }, [board.columns]);

        const calculateUserDownVotes = useCallback(() => {
            let count = 0;
            for (let i = 0; i < board.columns.length; i++) {
                for (let j = 0; j < board.columns[i].cards.length; j++) {
                    count += board.columns[i].cards[j].downVotesForCardFromUser;
                }
            }
            return count;
        }, [board.columns]);

        const votingEnable =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleVoting(board, userToken ?? "")
                    .catch(handleErrorMessage);
            }, 400, true);

        const downVotingEnable =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleDownVoting(board, userToken ?? "")
                    .catch(handleErrorMessage);
            }, 400, true);

        const showVotesEnable =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleVotesVisible(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const showCardAuthorsEnable =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleCardAuthorsVisible(
                        board,
                        !showCardAuthors,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const showCards =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleCardReveal(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const votesLimitedEnable =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleVotesLimited(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const playMusic =
            debounce((isActiveMusic?: boolean) => {
                BoardClient.getSingleton()
                    .togglePlayMusic(
                        board,
                        userToken ?? "",
                        isActiveMusic
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const handleResetDownVotes =
            debounce(() => {
                BoardClient.getSingleton()
                    .resetDownVotes(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);


        const sendMaxVotes =
            debounce(
                async (value: number | undefined) => {
                    BoardClient.getSingleton()
                        .setMaxVotes(
                            board,
                            value === undefined ? -1 : value,
                            userToken ?? ""
                        )
                        .catch(handleErrorMessage);
                }, 500, false);

        const setMultipleVotesAllowed =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleMultipleVotes(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const setHighlightMode =
            debounce((mode: HighlightModes) => {
                BoardClient.getSingleton()
                    .changeHighlightMode(
                        board,
                        mode,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const isVotingMessage = (msg: string) => {
            try {
                const jsonObj = JSON.parse(msg);
                return jsonObj.votingAction;
            } catch (e) {
                return false;
            }
        };

        const updateTrigger = (msg: string = "") => isVotingMessage(msg)
            ? handleMessageForVote(msg)
            : reloadBoard();

        const handleMessageForVote = (msg: string) => {
            const jsonObj = JSON.parse(msg);
            switch (jsonObj.votingAction) {
                case "ADD":
                    addVote(jsonObj.voterName, parseInt(jsonObj.cardId), parseInt(jsonObj.uniqueVotesForCard));
                    break;
                case "REMOVE":
                    removeVote(jsonObj.voterName, parseInt(jsonObj.cardId), parseInt(jsonObj.uniqueVotesForCard));
                    break;
                case "ADD_DOWN":
                    addDownVote(jsonObj.voterName, parseInt(jsonObj.cardId), parseInt(jsonObj.uniqueVotesForCard));
                    break;
                case "REMOVE_DOWN":
                    removeDownVote(jsonObj.voterName, parseInt(jsonObj.cardId), parseInt(jsonObj.uniqueVotesForCard));
                    break;
                default:
                    throw new Error("ParsingError: JSON is not a VotingAction object: " + msg);
            }
        }
        const socket = useMemo(() => new WebSocketService(msg => updateTrigger(msg)), []);
        useEffect(() => {
            socket.connectWebsocket(userToken ?? "")
        }, [userToken, socket]);

        const setReadinessRequested =
            debounce(() => {
                BoardClient.getSingleton()
                    .toggleReadinessRequested(
                        board,
                        userToken ?? ""
                    )
                    .catch(handleErrorMessage);
            }, 400, true);

        const startTimer =
            debounce(
                (duration: number) => {
                    BoardTimerClient.getSingleton().setBoardTimer(board, {
                        active: true,
                        name: board.timer.name,
                        duration: duration * 60
                    }, userToken ?? "").catch(handleErrorMessage);
                }, 400, true);

        const renderSidePanel = () => {
            return (
                <SidePanel
                    switchShowAuthorNames={showCardAuthorsHandler}
                    voting={board.voting}
                    votingEnable={votingEnable}
                    downVoting={board.downVoting}
                    downVotingEnable={downVotingEnable}
                    showVotes={board.showVotes}
                    setShowVotes={showVotesEnable}
                    showCardAuthors={board.showCardAuthors}
                    setShowCardAuthors={showCardAuthorsEnable}
                    editActive={editActive}
                    multipleVotesAllowed={board.multiVoteAllowed}
                    setMultipleVotesAllowed={setMultipleVotesAllowed}
                    maxVotes={maxVotes}
                    sendMaxVotes={sendMaxVotes}
                    votesLimited={board.votesLimited}
                    votesLimitedEnable={votesLimitedEnable}
                    playMusic={board.playMusic}
                    setPlayMusic={playMusic}
                    shouldPlayMusic={shouldPlayMusic}
                    setShouldPlayMusic={setShouldPlayMusic}
                    board={board}
                    setShowCards={showCards}
                    setHighlightMode={setHighlightMode}
                    name={name}
                    setReadinessRequested={setReadinessRequested}
                    startTimer={startTimer}
                    handleResetDownVotes={handleResetDownVotes}
                />
            );
        };


        useEffect(() => {
            if (maxVotes === undefined || maxVotes <= 0) {
                setVotesUnlimited(true);
            } else {
                setVotesUnlimited((calculateUserVotes() + calculateUserDownVotes()) > maxVotes);
            }
        }, [maxVotes, setVotesUnlimited, board, calculateUserVotes, calculateUserDownVotes]);

        return (
            <userTokenContext.Provider value={userToken}>
                <setErrorMessageContext.Provider value={handleErrorMessage}>
                    <handleConfirmationDialogPropsContext.Provider value={handleConfirmationDialogPropsChange}>
                        <div className={styles.boardPageDiv}>
                            <errorMessageContext.Provider value={errorMessage}>
                                <BoardHeaderComponent sorted={sorted}
                                                      setSorted={setSorted}
                                                      board={board}
                                                      editActive={editActive}
                                                      columns={columns}
                                >
                                    {renderSidePanel()}
                                </BoardHeaderComponent>
                                <Sound
                                    url={ELEVATOR_MUSIC!}
                                    playStatus={shouldPlayMusic && board.playMusic ? "PLAYING" : "STOPPED"}
                                    volume={5}
                                    loop={true}
                                />
                            </errorMessageContext.Provider>
                            <div className={styles.columnsDiv}>
                                <ColumnLayoutComponent
                                    value={showCardAuthors}
                                    board={board}
                                    columns={columns}
                                    name={name}
                                    votesVisible={board.showVotes}
                                    votingVisible={board.voting}
                                    downVotingVisible={board.downVoting}
                                    sorted={sorted}
                                    editActive={editActive}
                                    setEditActive={setEditActive}
                                    votesUnlimited={votesUnlimited}
                                    isCardEditable={isCardEditable}
                                    setIsCardEditable={setIsCardEditable}
                                />
                            </div>
                        </div>
                    </handleConfirmationDialogPropsContext.Provider>
                    <confirmationDialogContext.Provider value={confirmationDialogProps}>
                        <ConfirmationDialog
                            open={confirmationDialogProps.open}
                            message={confirmationDialogProps.message}
                            onAccept={confirmationDialogProps.onAccept}
                            onCancel={confirmationDialogProps.onCancel}
                        />
                    </confirmationDialogContext.Provider>
                </setErrorMessageContext.Provider>
            </userTokenContext.Provider>
        );
    }
;

export default BoardPage;
